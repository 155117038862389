import React,{useState,useEffect} from "react";

import { useInView } from 'react-intersection-observer'
import { Helmet } from "react-helmet";
import Asistentemodale from "../../aplikimepune/Asistentemodale";
import Financiermodale from '../../aplikimepune/Financiermodale';
import Inxhiniermodale from '../../aplikimepune/Inxhiniermodale';
import Juristmodale from '../../aplikimepune/Juristmodale';
import { t } from "i18next";
import ScrollToTop from "react-scroll-to-top";
const Aplikimpune = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const {ref:homeRef1,inView:homeVisible1}=useInView();
  const {ref:homeRef2,inView:homeVisible2}=useInView();
  const {ref:homeRef3,inView:homeVisible3}=useInView();


  return (
    <>
    <Helmet> 
        <meta charSet='utf-8' />
        <title>Aplikim pune</title>
        <meta name='description' content='ParidConsulting is your choice' />
        <meta name='keywords' content='parid, consulting, finace, smartsolution' />
    </Helmet>
    <div className='bg-slate-200 text-lg '>
    <ScrollToTop smooth />
    <div ref={homeRef3}>
   <div className='flex justify-center'>
   <h1 className='text-center text-black mb-8 mt-10 text-4xl tracking-widest  border-b-2 w-fit'>
      <p>{t("AplikimPerPune")}</p> 
    </h1>
    </div>   
        <div className="containeri-aplikimpune  xl:h-screen lg:h-screen md:h-screen sm:h2/3">
            <div className="flex  gap-6">
                <Financiermodale  />
                <Juristmodale  />
            </div>

            <div className="flex   gap-6">
              <Inxhiniermodale  />
              <Asistentemodale />
            </div>
        </div>
      </div>
  </div>
  </>
  );
};

export default Aplikimpune;

