import React ,{useEffect}from 'react'
import { Button, TextField } from "@mui/material";
import ScrollToTop from "react-scroll-to-top";

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next'

const Teardhuratpersonale = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {t,i18n}=useTranslation()
  return (
    <div>
    <ScrollToTop smooth />
 <Helmet> 
        <meta charSet='utf-8' />
        <title>Teardhuratpersonale</title>
        <meta name='keywords' content='parid,consulting,finance' />
        <meta name='description' content='paridconsulting' />
  </Helmet>
    <body className="bg-slate-100 shadow-md text-lg ">
    <div className="mx-auto max-w-full  py-6 px-8 xl:px-56  md:px-14 sm:px-6 lg:px-8">
        <h1 className=" tracking-tight text-4xl   mt-6 text-left">
            {t('deklarimtatimi')}
        </h1>
      </div>
      <div className="mx-auto max-w-full xl:pb-0 lg:pb-0 md:pb-36 sm:pb-0 xl:px-56 lg:px-8 md:px-14  px-8  py-6  text-lg   ">
        <div className="mt-2 mb-6  xl:flex md:grid sm:grid gap-20 ">
          <p className="xl:w-7/12   lg:w-w-full md:w-w-full  sm:w-full text-left mb-9 justify-start items-start grid ">
            <div >
              {/* //////////////////////////////////////////////////////////////////////////////////////////////////////// */}
           
           
                <div className="mb-2 ">
                <p className='grid justify-start'>

                 <p className='flex text-left w-6/6'>{t('njebiznes')}</p>  
              <br/><br/><br/>  <span className='font-semibold'> 1.{t('Njohuri')}</span> <br/>
            
              {t('Njekonsulent')}
              
              <br/><br/>
              
              
               {t('eshteekuptueshme')}
              
              <br/><br/><br/><span className='font-semibold'> 2.{t('Planifikimi')}</span><br/>
          
                {t('njeplanbiznesi')}
              
              <br/><br/>   <span className='font-semibold'>3.{t('Investimi')}</span><br/>
              {t('qellimijuaj')}
              <br/><br/>    <span className='font-semibold'>4.{t('Kursimparash')}</span><br/>
              {t('edhepsepaguani')}
              <br/><br/>  <span className='font-semibold'>5.{t('pikpamja')}</span><br/>
    
                {t("BiznesiJuaj")}
     
              <br/><br/> <span className='font-semibold'>{t("Stabilitet")}</span><br/>
              
                {t("KonsulenceFinanciareIkeshilluar")}
              
              <br/><br/>  <span className='font-semibold'>{t("SiguriaEseArdhmes")}</span><br/>
           
                {t("PlanifikimIvazhdimsisSeBiznesit")}
                </p>
                               
                              </div>
       
         
              {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
             
            </div>
        
          </p>
          <div className="xl:w-5/12 lg:w-full md:w-full  sm:w-full xl:h-2/3 lg:h-full md:h-full sm:h-full rounded-lg shadow-lg text-lg  border-t-4 border-l-4 border-r-4">
              <form
                action="https://formsubmit.co/info@paridconsulting.al"
                method="POST"
                className="grid gap-5  kontakt-forma"
              >
             
                   <p className="font-semibold tracking-wide text-left">
                   {t('keninevojepersherbimintone')}  
                  </p>
                {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}
              
                   <p className="text-left">
                   {t('nakontaktoni')}  
                  </p>
                {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}
              
            
                  <TextField
                    type="text"
                    name="Name"
                    label={t('futemrin')}  
                    size="small"
                    required
                  />
                {/* //////////////////////////////////////////////////////////////////////////////////////////// */}
              
                  <TextField
                    type="text"
                    name="Numri"
                    label={t('numri')}  
                    size="small"
                    required
                  />
                <input type="hidden" name="_captcha" value="false" />
                <input
                  type="hidden"
                  name="_next"
                  value="http://localhost:3000/Thankyou"
                />
                <select
                  name={t('zgjidhninjesherbim')} 
                  className="w-full h-10 bg-transparent border text-gray-500 border-gray-400 rounded-md"
                >
                  
                  <option>{t('option1')} </option>
                  {/* /////////////////////////////////////////////////////// */}
   
                   <option>{t('option2')} </option>
                  {/* /////////////////////////////////////////////////////// */}
 
                  <option>{t('option3')} </option>
                  {/* /////////////////////////////////////////////////////// */}
     
                   <option>{t('option4')} </option>
                  {/* /////////////////////////////////////////////////////// */}
                
                  <option>{t('option5')} </option>
                  {/* /////////////////////////////////////////////////////// */}
   
                    <option>{t('option6')} </option>
                </select>
                {/* /////////////////////////////////////////////////////// */}
             
                    <Button variant="outlined" type="submit">
                    {t('dergo')}
                  </Button>
              </form>
            </div>
        </div>
      </div>
    </body>


  </div>
  )
}

export default Teardhuratpersonale