import React, { useState,Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  PlayIcon,
  XMarkIcon,
  InformationCircleIcon,
  UserGroupIcon,
  BuildingOffice2Icon,
  ChartBarSquareIcon,
  SquaresPlusIcon,
  CurrencyEuroIcon,
  DocumentCheckIcon,
  BuildingLibraryIcon,
  UserPlusIcon,
  MapIcon,
  PhoneIcon,
  AtSymbolIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import wLogo from "../../assetes/whiteLogoja.png"
import i18next from 'i18next';
import alb from '../../assetes/language/alb.png'
import EN from '../../assetes/language/united-kingdom.png'
import it from '../../assetes/language/it.png'
import { useTranslation } from 'react-i18next'




const NavItem = () => {

  const {t} = useTranslation()

    const hanldeChangelng = (lng) =>{
      window.location.reload()
      i18next.changeLanguage(lng)
      localStorage.setItem('lng',lng)
    }

  const solutions = [
    {
      name: `${t('dropDownRrethParid')}`,
      href: '/ParidConsulting',
      icon: InformationCircleIcon
    },
    {
      name: `${t('stafiYne')}`,
      href: '/ParidConsulting',
      icon: UserGroupIcon,
    },
   
   
  ]
const contact = [
    {
      address: 'Rruga Abdyl Frashëri, Tirana, Albania',
      icon: MapIcon
    },
    {
      tel: '+355 69 60 60 818',
      icon: PhoneIcon,
    },
    { 
      email: 'info@paridconsulting.al ', 
      icon: AtSymbolIcon 
    },
   
  ]

  const resources = [
    {
      name: `${t('KonsulenceFinanceDropDown')}`,
      href: '/KosulenceFinanciare',
      icon: ChartBarSquareIcon,
    },
    {
      name: `${t('SherbimKontabelDropNav')}`,
      href: '/SherbimKontabiliteti',
      icon: SquaresPlusIcon,
    },
    {
      name: `${t('KonsulenceBankareDropNav')}`,
      href: 'KonsulenceBanke',
      icon: CurrencyEuroIcon,
    },
    {
      name: `${t('KonsulenceFiskaleDropNav')}`,
      href: '/SherbimFiskal',
      icon: DocumentCheckIcon,
    },
    {
      name: `${t('VleresimIpasuriveDropNav')}`,
      href: '/PasuriteEpaluajtshme',
      icon: BuildingLibraryIcon,
    },
  
  ]

 
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div>
{/* relative container bg-transparent z-50 */}
    <Popover className="">
    <div className="navbari navbari2  z-50">
    
            <Link to="/">
            <img src={wLogo} className='h-12' />
          </Link>
        <div className=" md:hidden">
          <Popover.Button className="inline-flex items-center justify-center rounded-md menu-icon border border-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <Bars3Icon className="h-6 w-6 text-orange-200" aria-hidden="true" />
          </Popover.Button>
        </div>
        <Popover.Group as="nav" className="hidden space-x-10 md:flex">
          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? 'text-gray-100' : 'text-gray-300',
                    'group inline-flex items-center rounded-md  text-base font-medium hover:text-gray-100 focus:outline-none  focus:ring-offset-2'
                  )}
                >
                  <Link to='/' >Home</Link>
              
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                </Transition>
              </>
            )}
          </Popover>

          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? 'text-gray-100' : 'text-gray-300',
                    'group inline-flex items-center rounded-md  text-base font-medium hover:text-gray-100 focus:outline-none  focus:ring-offset-2'
                  )}
                >
                  <span>{t('RrethneshNav')}</span>

                  <ChevronDownIcon
                    className={classNames(
                      open ? 'text-gray-100' : 'text-gray-300',
                      'ml-2 h-5 w-5 group-hover:text-gray-500'
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                        {solutions.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                          >
                            <item.icon className="h-6 w-6 flex-shrink-0 text-sky-800" aria-hidden="true" />
                            <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">{item.name}</p>
                              <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                            </div>
                          </Link>
                        ))}
                      </div>
  
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? 'text-gray-100' : 'text-gray-300',
                    'group inline-flex items-center rounded-md  text-base font-medium hover:text-gray-100 focus:outline-none  focus:ring-offset-2'
                  )}
                >
                  <span>{t('kontaktNav')}</span>

            
                  <ChevronDownIcon
                    className={classNames(
                      open ? 'text-gray-100' : 'text-gray-300',
                      'ml-2 h-5 w-5 group-hover:text-gray-500'
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                        {contact.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                          >
                            <item.icon className="h-6 w-6 flex-shrink-0 text-sky-800" aria-hidden="true" />
                            <div className="ml-4">
                            <a className='flex'
                            href="https://www.google.com/maps/place/Rruga+Abdyl+Frash%C3%ABri,+Tirana,+Albania/@41.3191333,19.8155158,17z/data=!3m1!4b1!4m5!3m4!1s0x13503102cb31d51b:0x553d27289980062e!8m2!3d41.3191293!4d19.8177045">
    
                          <span className='text-gray-700 text-left'>{item.address}</span>
                          </a>
                          <div className='flex'>
                           <a href="tel:123-456-7890" className='text-gray-700'> 
                              {item.tel}
                           </a>
                             </div>
                            <address className='text-gray-700'>
                                <a href="mailto:webmaster@example.com" className='flex gap-2'>    
                               
                               {item.email}</a>
                            </address>
                              <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                            </div>
                          </Link>
                        ))}
                      </div>
          
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

   
   

          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? 'text-gray-100' : 'text-gray-300',
                    'group inline-flex items-center rounded-md  text-base font-medium hover:text-gray-100 focus:outline-none  focus:ring-offset-2'
                  )}
                >
                  <span>{t('SherbimetNav')}</span>
               
                  <ChevronDownIcon
                    className={classNames(
                      open ? 'text-gray-100' : 'text-gray-200',
                      'ml-2 h-5 w-5 '
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform px-2 sm:px-0">
                    <div className="overflow-hidden rounded-lg shadow-lg ">
                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                        {resources.map((item) => (
                          <Link
                            key={item.emri}
                            to={item.href}
                            className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                          >
                            <item.icon className="h-6 w-6 flex-shrink-0 text-sky-800" aria-hidden="true" />
                            <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">{item.name}</p>
                             
                              <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                            </div>
                          </Link>
                        ))}
                      </div>

                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>


          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? 'text-gray-100' : 'text-gray-300',
                    'group inline-flex items-center rounded-md  text-base font-medium hover:text-gray-100 focus:outline-none  focus:ring-offset-2'
                  )}
                >
                    <h1>{t('Gjuhet')}</h1>
                  <ChevronDownIcon
                    className={classNames(
                      open ? 'text-gray-100' : 'text-gray-200',
                      'ml-2 h-5 w-5 '
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform px-2 sm:px-0">
                    <div className="overflow-hidden rounded-lg shadow-lg ">
                      <div className="relative flex flex-col bg-white px-5 py-6 sm:gap-3 sm:p-8 
                      ">
                          <span className='cursor-pointer border p-1 hover:bg-sky-800 hover:text-white rounded-md flex items-center' onClick={()=>{hanldeChangelng('al')}}><img className='w-6  rounded-full  mr-9' src={alb} />Shqip</span>
                          <span className='cursor-pointer border p-1 hover:bg-sky-800 hover:text-white rounded-md flex items-center' onClick={()=>{hanldeChangelng('en')}}><img className='w-6  rounded-full  mr-9' src={EN} />English</span>
                          <span className='cursor-pointer border p-1 hover:bg-sky-800 hover:text-white rounded-md flex items-center' onClick={()=>{hanldeChangelng('it')}}><img className='w-6  rounded-full  mr-9' src={it} />Italian</span>
                      </div>

                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
  
        </Popover.Group>

   
    </div>

    <Transition
      as={Fragment}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel focus className="absolute z-50 inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
        <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="px-5 pt-5 pb-6">
            <div className="flex items-center justify-between">

              <div className="-mr-2">
                <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 ">
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="mt-6">
              <nav className="grid gap-y-8">
              <h1 className='tracking-widest border-b text-start'>Rreth nesh</h1>
                {solutions.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                  >
                    <item.icon className="h-6 w-6 flex-shrink-0 text-sky-800" aria-hidden="true" />
                    <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                  </Link>
                ))}
              </nav>
            </div>
          </div>
          
          <div className="space-y-6 py-6 px-5">
            <div className=" grid  gap-y-4 gap-x-8">
              <h1 className='tracking-widest border-b text-start'>Shërbimet</h1>
           {resources.map((item)=>(
        
              <Link
                    key={item.name}
                    to={item.href}
                    className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                  >
                    <item.icon className="h-6 w-6 flex-shrink-0 text-sky-800" aria-hidden="true" />
                    <span className="ml-3 text-left text-gray-900">{item.name}</span>
                  </Link>
         
             ))}
            </div>
            <div className=" grid  gap-y-4 gap-x-8">
              <h1 className='tracking-widest border-b text-start'>Kontakt</h1>
              {contact.map((item) => (
                          <div className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50">
                            <item.icon className="h-6 w-6 flex-shrink-0 text-sky-800" aria-hidden="true" />
                            <div className="ml-4">
                            <a className='flex'
                            href="https://www.google.com/maps/place/Rruga+Abdyl+Frash%C3%ABri,+Tirana/@41.3191293,19.8177045,17z/data=!3m1!4b1!4m5!3m4!1s0x13503102cb31d51b:0x553d27289980062e!8m2!3d41.3191293!4d19.8177045">
    
                          <span className='text-gray-700 text-left'>{item.address}</span>
                          </a>
                          <div className='flex'>
                           <a href="tel:123-456-7890" className='text-gray-700'> 
                              {item.tel}
                           </a>
                             </div>
                            <address className='text-gray-700'>
                                <a href="mailto:info@paridconsulting.al" className='flex gap-2'>    
                               
                               {item.email}</a>
                            </address>
                              <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                            </div>
                          </div>
                        ))}
            </div>
            
           <div>
           <Popover className="relative text-start">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? 'text-gray-800' : 'text-gray-800',
                    'group inline-flex items-center rounded-md  text-base font-medium  focus:outline-none  focus:ring-offset-2'
                  )}
                >
                    <h1 className=''>{t("Gjuhet")}</h1>
                  <ChevronDownIcon
                    className={classNames(
                      open ? 'text-gray-800' : 'text-gray-800',
                      'ml-2 h-5 w-5 '
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform px-2 sm:px-0">
                    <div className="overflow-hidden rounded-lg shadow-lg ">
                      <div className="relative grid gap-2 bg-white px-5 py-6 sm:gap-3 sm:p-8 ">
                          <span className='cursor-pointer border p-1 hover:bg-sky-800 hover:text-white rounded-md flex items-center' onClick={()=>{hanldeChangelng('al')}}><img className='w-6  rounded-full  mr-9' src={alb} />Shqip</span>
                          <span className='cursor-pointer border p-1 hover:bg-sky-800 hover:text-white rounded-md flex items-center' onClick={()=>{hanldeChangelng('en')}}><img className='w-6  rounded-full  mr-9' src={EN} />English</span>
                          <span className='cursor-pointer border p-1 hover:bg-sky-800 hover:text-white rounded-md flex items-center' onClick={()=>{hanldeChangelng('it')}}><img className='w-6  rounded-full  mr-9' src={it} />Italian</span>
                      </div>

                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
           </div>
           <div>
           </div>

          </div>
        </div>
      </Popover.Panel>
    </Transition>
  </Popover>


  </div>

  )
}

export default NavItem







