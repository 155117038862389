import './App.css';
import Home from './components/home/Home';
import NavItem from './components/navbar/NavItem';
import Footer from './components/navbar/Footer';
import { useEffect, useState } from 'react';
import { Button, MenuItem, TextField } from '@mui/material';
import { Select } from 'antd';
import { color } from '@mui/system';

import useStorage from './utils/UseStorage';
import Trajnimetepergjithshme from './components/rrethnesh/certifikime/Trajnimetepergjithshme';
import Certfikimedhetrajnime from './components/rrethnesh/certifikime/Certfikimedhetrajnime';
import Aplikimpune from './components/rrethnesh/certifikime/Aplikimpune';
import PasuriteEpaluajtshme from './components/sherbimet/PasuriteEpaluajtshme';
import SherbimFiskal from './components/sherbimet/SherbimFiskal';
import KonsulenceBanke from './components/sherbimet/KonsulenceBanke';
import SherbimKontabiliteti from './components/sherbimet/SherbimKontabiliteti';
import KosulenceFinanciare from './components/sherbimet/KosulenceFinanciare';
import PartneretTane from './components/rrethnesh/PartneretTane';
import ParidConsulting from './components/rrethnesh/ParidConsulting';
import Thankyou from './components/sherbimet/Thankyou';
import Deklarimiifitimit from './components/Lajme/Deklarimiifitimit';
import Aktinormativ from './components/Lajme/Aktinormativ';
import Teardhuratpersonale from './components/Lajme/Teardhuratpersonale';
import {
  Routes,
  Route,
} from "react-router-dom";
import './components/home/slider/style.scss'
import { Helmet } from 'react-helmet';
import Kontakt from './components/sherbimet/Kontakt';
import Slideri2 from './components/home/slider/Slideri2';
import Loading from './components/Loading';
function App() {
  const [isLoading, setIsLoading] = useState(true);
  setTimeout(() => {
   
   setIsLoading(false);
 }, 1000);

  return ( 
    <div className="App" >
      <Helmet> 
        <meta charSet='utf-8' />
        <title>ParidConsulting</title>
        <meta name='description' content='ParidConsulting is your choice' />
        <meta name='keywords' content='parid, consulting, finace, smartsolution' />
      </Helmet>
      {isLoading? (<Loading />):(
        <>
         <div >
          <NavItem  />
        </div>
        <Routes>
          <Route  path='/' element={<Home /> } />
          <Route  path='/ParidConsulting' element={<ParidConsulting /> } />
          <Route  path='/PartneretTane' element={<PartneretTane /> } />
          <Route  path='/KosulenceFinanciare' element={<KosulenceFinanciare /> } />
          <Route  path='/SherbimKontabiliteti' element={<SherbimKontabiliteti  /> } />
          <Route  path='/KonsulenceBanke' element={<KonsulenceBanke  /> } />
          <Route  path='/SherbimFiskal' element={<SherbimFiskal  /> } />
          <Route  path='/PasuriteEpaluajtshme' element={<PasuriteEpaluajtshme  /> } />
          <Route  path='/thankyou' element={<Thankyou /> } />
          <Route  path='/Aplikimpune' element={<Aplikimpune/> } />
          <Route  path='/Certfikimedhetrajnime' element={<Certfikimedhetrajnime /> } />
          <Route  path='/Trajnimetepergjithshme' element={<Trajnimetepergjithshme /> } />
          <Route  path='/Aktinormativ' element={<Aktinormativ /> } />
          <Route  path='/Deklarimiifitimit' element={<Deklarimiifitimit /> } />
          <Route  path='/Teardhuratpersonale' element={<Teardhuratpersonale  /> } />
          <Route  path='/kontakt' element={<Kontakt  /> } />
          <Route  path='/Slideri2' element={<Slideri2/> } />
        </Routes>
        <div>
          <Footer/>
        </div>
        </>)}
       
    </div>
  );
}

export default App;
