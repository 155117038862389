import React,{useEffect} from 'react'
import { Button, TextField } from '@mui/material'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next'
import ScrollToTop from "react-scroll-to-top";
const Trajnimetepergjithshme = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {t,i18n}=useTranslation()
  return (
    <div>
    <ScrollToTop smooth />
    <Helmet> 
        <meta charSet='utf-8' />
        <title>Aplikimpune</title>
        <meta name='description' content='ParidConsulting is your choice' />
        <meta name='keywords' content='parid, consulting, finace, smartsolution' />
    </Helmet>
<body className="bg-slate-100 shadow-md text-lg ">
      <div className="mx-auto max-w-full py-6 px-8 xl:px-48  md:px-14 sm:px-6 lg:px-8">
       <h1 className=" tracking-tight text-5xl text-left mt-6">{t('trjanimetepergjithshme')}</h1>
     </div>
     <div className="mx-auto max-w-full xl:px-44 md:px-14 py-6 px-4  sm:px-6 lg:px-8">
    <div className='mt-2 mb-6  xl:flex lg:flex md:grid sm:grid gap-20 '>
      <ul  className='xl:w-7/12  lg:w-7/12 md:w-7/12  sm:w-full  mb-9 justify-start items-start grid list-decimal'>
     
       <div className='ml-6'>
        <p className='font-bold tracking-tight text-left mb-4 text-xl'>
          {t('ofrontrajnim')}
          </p>
        <p className='mb-2 '>
          <p className='f tracking-tight text-left text-xl'>
          {t('trajnimiavancuar')}
       </p>
        <ul className='list-disc p-4 text-left'>
          <li >{t("financa5")}</li>
          <li >{t("exelku")}</li>
          <li >{t("legjislascion")}</li>
          <li >{t("efiling")}</li>
          <li >{t("llogaritjaepages")}</li>

         
        </ul>
        
        </p>
        
        
        
        <p className='mb-2 '><p className='font-bold tracking-tight text-left text-xl'>{t("trajnimibaze1")}</p>
        <ul className='list-disc p-4 text-left'>
          <li>{t("trajnimibaze")}</li>
          <li>{t("AlphaWeb")}</li>
          <li>{t("Financapesa")}</li>
          <li>{t("legjtatimor")}</li>
        </ul>
        </p>

        <p className='mb-2 '><p className='font-bold tracking-tight text-left  text-xl'>{t("neofrojme")}</p>
        <ul className='list-disc p-4 text-left'>
          <li>{t("certifikateelicensuar")} </li>
          <li>{t('prakintensive')}</li>
          <li>{t("ulje10")}</li>
          <li>{t("ulje50")}</li>
          <li>{t("ulje50familje")}</li>
          <li>{t("studentetmetemire")}</li>
         
        </ul>
        </p>


        </div>
      </ul>
     
      
      <div className="xl:w-5/12 lg:w-full md:w-full sm:w-full xl:h-2/3 lg:h-full md:h-full sm:h-full rounded-lg shadow-lg text-lg  border-t-4 border-l-4 border-r-4">
              <form
                action="https://formsubmit.co/info@paridconsulting.al"
                method="POST"
                className="grid gap-5  kontakt-forma"
              >
              <p className='font-semibold text-lg tracking-wide text-left'>
                {t('perfundonstudimet')} 
                </p>
              <p className='font-bold text-lg tracking-wide text-left'>
                {t('ndoshtajediplomuar')} 
                </p>
              <p className='text-left'>{t('nakontaktoni')} </p>
                  
            
                  <TextField
                    type="text"
                    name="Name"
                    label={t('futemrin')}  
                    size="small"
                    required
                  />
                {/* //////////////////////////////////////////////////////////////////////////////////////////// */}
              
                  <TextField
                    type="text"
                    name="Numri"
                    label={t('numri')}  
                    size="small"
                    required
                  />

                   <TextField 
                   type='email'
                    name='Email' 
                    label={t('futemail')}  
                    size='small' 
                    required />
                      <TextField
                       type='text'
                        name='Subjekti' 
                        label={t('Subjekt')} 
                         size='small' required />
                <input type="hidden" name="_captcha" value="false" />
                <input
                  type="hidden"
                  name="_next"
                  value="http://localhost:3000/Thankyou"
                />
              
                  <select name='Karte studenti' required className='w-full h-10 p-2 bg-transparent border text-gray-500 border-gray-400 rounded-md'>
                 <option value="" >{t('Kartestudenti')} </option>
                   <option className='text-black'>{t('po')}</option>
                  <option className='text-black'>{t('jo')}</option>
              
                  </select>
                  <textarea 
                  type='text'
                  name='Komenti' 
                  placeholder={t('komenti')}
                  className='p-3  bg-transparent border text-gray-500 border-gray-400 rounded-md '
                  required>
                </textarea>
               
             
                    <Button variant="outlined" type="submit">
                    {t('dergo')}
                  </Button>
              </form>
            </div>



     
    </div>
    
  </div>
</body>




</div>
  )
}

export default Trajnimetepergjithshme