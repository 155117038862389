import React, {useState, useEffect, useRef } from 'react';
import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'
import { Polygon } from 'react-leaflet/hooks'
import { Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
import useGeolocation from '../hooks/useGeolocation';
import L from 'leaflet'
const center=[41.3189635, 19.8172136]
const Harta = () => {
  const markerIcon = new L.Icon({
    iconUrl:require('../../assetes/homeIcon/location.png'),
    iconSize:[50,50],
    iconAnchor:[17,46],
    popupAnchor:[3,-46]
  })

  const map = `https://www.openstreetmap.org/search?query=blloku%20tiranan#map=20.94/41.3189635/19.8172136`
  console.log({map})

  const location = useGeolocation()
  return (
    <div className='kryesore-hartes' >

 
    <MapContainer className='harta' center={center} zoom={15} scrollWheelZoom={true}>
  <TileLayer
    attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
    url="https://api.maptiler.com/maps/streets-v2/256/{z}/{x}/{y}.png?key=ipYlSeD6n6NjJHArzlZU"
  />
  {location.loaded && !location.error && (
    <Marker icon={markerIcon} position={[41.3189635, 19.8172136]}>
    <Popup>
    <p className='text-lg'>Parid Consulting</p> 
     <p>Hyrja ndodhet tek tyneli midis BKT dhe COHL'S<br/>
     </p>
    </Popup>
  </Marker>
  )}
</MapContainer>

</div>
  );
}

export default Harta;
