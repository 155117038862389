import React from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Thankyou = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='bg-white'>

        <header className="shadow-md h-screen flex justify-center" >
          <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-medium tracking-wider text-gray-700 mb-10 mt-6">Faleminderit!</h1>
            <p className='mb-8'>Ne do t'ju kontaktojme së shpejti !</p>
            <Link to={'/'}>  <Button variant='contained'> <ArrowBackIcon/>Kthehu pas</Button>  </Link>
          </div>
        </header>
   
    </div>
  )
}

export default Thankyou