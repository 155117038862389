import i18next, { init } from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        apliko:'Apply',
        ketumund:'Here, it can be you!',
        studentetmetemire:`For the best students of the course, employment opportunities according to
        job offers to our clients.`,
        legjtatimor:`Tax Legislation`,
        Financapesa:`Financa 5`,
        trajnimibaze:`'Self Care' where you will get basic knowledge on reporting
        fiscalization for the data of the business unit, the operator,
        fiscalized invoices, customs declarations and master configurations
        of the taxpayer;`,
        trjanimetepergjithshme:`General training`,
        kursetrajnimine:`Parid Consulting offers professional training courses in the field of ECONOMY`,
        komenti:`Leave your comment`,
        pikpamja:`Pikëpamja e Palës së Tretë`,
        edhepsepaguani:`Although you will pay a financial consultant, the long-term savings will justify the cost.
        Your financial advisor focuses on one thing about your business: money.
         It's all about making the smartest financial decisions to increase your chances of success.
          They can guide you to the best opportunities to increase your earnings.`,
        Kursimparash:`Saving money`,
        qellimijuaj:`Your goal is to build your business and succeed, maximizing your profit. Smart investments are critical to achieving these goals.
        Most business owners either lack the knowledge or the time (or both) to analyze and evaluate investment opportunities.
        A financial consultant with the right qualifications and knowledge can guide you to the investments that are right for your business. `,
        Investimi:`Investing`,
        njeplanbiznesi:`A business plan is critical to the success of your business.
        You need to know where you are going, how you are getting there and what to do if you encounter obstacles along the way.
         A good financial consultant can design a comprehensive plan to help you run your business in the right way
          as efficiently as possible and prepare for problems that arise later.`,
        Planifikimi:'Planning',
        eshteekuptueshme:`It's understandable that you want to save money by doing some work yourself, but dealing with finances requires knowledge and training. If it's not your field, 
        you could be doing your business more harm than good by trying to manage it yourself.`,
        Njekonsulent:`A skilled and experienced financial consultant knows the laws and methods to help you get the most out of your finances.
        of your business.
       They can work with you to regularly review your financial situation to prevent serious mistakes and to
       quickly correct any wrong decisions related to the mismanagement of cash flow.`,
        pershkrimi: `Conceived long before its founding,
            Parid Consulting, comes as one of the most serious consulting studios in the country,
            to meet the needs of clients and individuals seeking to achieve professionalism.`,
        pyetjet: `About us?`,
        kontakt: `Contact us`,
        lajme: `News and events`,
        sherbime: `Services`,
        konsulencefinanciare: `Financial and legal consultancy`,
        kontabiliteti: `Accounting services`,
        konsulence: `Banking consultancys`,
        fiskalue: `Fiscal services`,
        pasuria: `Real estate appraisals`,
        burime: `Human resources`,
        certifikime: `Certifications and trainings`,
        Trajnime: "Professional training",
        Shiko: `More`,
        trajnimetepergjithshme: `Training`,
        Aplikim: `Apply for work `,
        shteseligji: `Normative Act No. 27 – For an addition to Law No. 9920, dated 19.05.2008`,
        deklarimi: `Declaration of Simplified Income Tax - Small Business`,
        deklarimtatimi: `Declaration of Personal Income Tax`,
        benefite: `7 benefits that your business benefits from financial consulting`,
        slideri: `Our Partners`,
        PerseParid: "Why Parid Consulting?",
        RrethneshPershkrimi: `Conceived long before its founding, Parid Consulting, comes as
            one of the most serious consulting studios in the country, to
            meet the needs of clients and individuals seeking to achieve
            professionalism. Parid Consulting has a team of dedicated
            professionals in different fields where they hold various
            professional titles. Academic achievements as well as
            professional experience in leadership roles in the banking and
            accounting studios, bring to you a dignified service that
            reflects our values and abilities.`,
        we: `We take care of your business from the very first steps by
            providing project analysis to their implementation.`,
        studio: `  Studio offers a complete package of services to local and
            foreign entities such as financial consultancy, banking and
            fiscal, business plans, start-up activities, bookkeeping
            accounting, credit analysis, evaluation of real estate and human
            resource training. Our goal is to establish a structure to
            support our customers in their daily work and duties, providing
            personalized and value-added services, in accordance with
            changes in their requirements.`,
        offer: ` Studio offers a complete package of services to local and
            foreign entities such as financial consultancy, banking and
            fiscal, business plans, start-up activities, bookkeeping
            accounting, credit analysis, evaluation of real estate and human
            resource training. Our goal is to establish a structure to
            support our customers in their daily work and duties, providing
            personalized and value-added services, in accordance with
            changes in their requirements.`,
        stafi: `Our staff`,
        drejtor: ` The Director`,
        supervizor: `Supervisor`,
        supervizore: `Supervisory`,
        financier: `Financial analyst`,
        financiere: `Financial analyst`,
        konsulencebankare: `Banking consultancy`,
        dukefiluarqenga: `Starting from general consultancy for the loan, preparation
            of documentation, negotiations with banks for better
            conditions to the loan disbursement.`,
        konsulencenelidhje: `Consultancy on Bank Guarantees and Letters of Credit,
            preparation of documentation, negotiations with banks, etc.`,
        depozitakonsulenca: `Deposits, consulting for the best interests of the market
            and for the safest banks.`,
        cdollojkonsulence: `Any other banking services consultancy.`,
        keninevojepersherbimintone: ` How can we help you?`,
        nakontaktoni: `Contact us via email or fill out the below questionnaire and
            we will contact you.`,
        futemrin: `Name and Surname`,
        numri: `Number`,
        zgjidhninjesherbim: "Select a service",
        option1: `Financial and legal consultancy`,
        option2: `Accounting services`,
        option3: `Banking consultancy`,
        option4: `Fiscal services`,
        option5: `Real estate valuations`,
        option6: `Human resource`,
        dergo: `Send`,
        Kontakto: `Contact`,
        futemail: `Email`,
        Subjekt: `Subject`,
        Mesazhiii: `Message`,
        pika1: ` Analysis and orientation for starting new businesses.`,
        pika2: ` Financial and fiscal study for start-up projects suggesting
            the best forms of financing.`,
        pika3: ` Analyzing the projects and the financial indicators related
            to profitability of new businesses.`,
        pika4: `  Informing and updating businesses on various issues related
            to tax legislation and its implementation.`,
        pika5: `   Representation in relations with QKR and QKL (business
                registration, status change, closure, etc.)`,
        pika6: `  Preparation the documents for tender (public procurement).`,
        pika7: ` Assist and represent our clients in the appeal process at
            the Tax Appeal Office.`,
        pika8: ` Preparation of the employment contracts (collective or
                Individual).`,
        pika9: ` Compilation of personal files for each employee (Workbooks,
                Insurance booklets, etc.)`,
        pika10: `   Prepare various reports and assessments for choosing the
              most profitable way of possible future investments.`,
        astudio: ` Parid Consulting in collaboration with "A STUDIO", offers a
             personalized service to support our clients with the following
             services:`,
        apartamente: `Appartments`,
        komerciale: `Commercial Unit`,
        hotel: "Hotel",
        Hec: `HEC`,
        traull: `Land`,
        vlersimtelinjave: `Evaluation of technological lines and machinery`,
        automjete: `Light and heavy vehicles.`,
        lista1: `Tax declarations referring to the legislation in force
             mainly on VAT, Withholding Tax, Income Tax, Payroll, Social
             and Health Insurance, Purchase and Sale Books as well as
             Declaration and Removal of Employees.`,
        lista2: ` Following procedures during various tax audits.`,
        lista3: ` Continuous Support by personnel responsible.`,
        lista4: `   Review of periodic tax declarations.`,
        lista5: `   Administration, representation regarding disputes with tax
             administration.`,

        RrethneshNav: "About us",
        kontaktNav: "Contact",
        SherbimetNav: "Service",
        dropDownRrethParid: "About ''Parid Consulting''",
        stafiYne: "Our Staff",
        SherbimKontabelDropNav: "Accounting Services",
        KonsulenceFinanceDropDown: "Financial and Legal Consultancy",
        KonsulenceBankareDropNav: "Banking Consultancy",
        KonsulenceFiskaleDropNav: "Fiscal Services",
        VleresimIpasuriveDropNav: "Real Estate Appraisal",

        SherbimKontabiliteti: "Accounting services",
        RaporteDheAnaliza:
          "Different reports and analyzes for decision-making purposes.",
        PergatitjeTeRaporteve:
          "Preparation of financial and accounting reports in connection with the performance and progress of your business.",
        MbajtjaEploteEllogaritjeve: "Full bookkeeping.",
        PërgatitjeTëPasqyraveFfinanciare:
          "Preparation of annual as well as monthly financial statements in accordance with National and International Accounting Standards.",
        NdjekjeEprocedurave:
          "Follow-up of the Company’s liquidation, winding-up and bankruptcy accounting procedures.",
          AplikimPerPune:"Apply for a job",
          LlojiIpunes:'Financial analyst',
          PershkrimiIpunes:"Financial Analyst - full time",
          DetajetPerPunen:"Fill in the fields below and we will contact you as soon as we can.",
          NgarkoCV:"Upload your CV:",
          
          Gjuhet:"Language",
         Nekreun:`In chapter XV, after point 6, of the article "Transitional provisions", of law no. 9920, dated 19.5.2008,
         "On tax procedures", amended, point 7 is added, with the following content:
         "7. Except for the provisions in this law, for administrative offenses related to
         the terms of entry into force and the effective implementation of law no. 87/2019, "On the invoice and the system of
         traffic monitoring", amended, the implementation of sanctions will begin to be applied after
         dated January 1, 2022."`,
         njebiznes: `A business has many responsibilities and expenses and of course you don't need to pay extra bills.
         According to experts, over 60% of business owners do not have a financial consultant.
         If you are one of them, you may be taking a big risk for the future of your business and yourself.
         But why does this happen, and what benefits come with a financial consultant?>
         Running a business is full of challenges. There are always situations that don't go right,
         so you want to make sure you minimize the consequences. Money mismanagement, cash flow problems,
          late payments, tax issues and other financial problems can be critical enough to shut down a business.
           This is why it is so important to control the financial aspects of your business. Hiring a consultant
           financial advisor with the right experience can prevent your business from going bankrupt.
          There are many ways a qualified financial consultant can be your business partner
         yours to flourish.`,
         Njohuri:'Knowledge',
         perfundonstudimet:`Are you finishing your studies on economics and you still don't know what is needed in the job market?`,
         ndoshtajediplomuar:`Or maybe you graduated but haven't started working on your profile yet?`,
         Kartestudenti:`- Student Cart -`,
         po:'Yes',
         jo:'No',
         trajnimeprofesionale:`Professional training and accounting practice`,
         TrajnimetNeFinance:"We prepare you as a financier in PRACTICE",
         TrajnimeNeAlphaDheFinance:"We offer intensive training in the Alpha Web and Finance 5 program",
         DeklarimetOnline:"We teach you how to periodically manage a subject from the Financial side, including Online Statements up to the Annual Balance",
         TrajnimeMeEksperte:"PARID CONSULTING organizes training with lecturers such as Accounting Experts, Chartered Accountants and Fiscal Experts",
         ProgrmaiExcelIperfshire:"Office EXCEL package program included in the package price",
         UljePerStudente:"10% discount for students with a student card",
         TrajnimetOrganizohenNe6Grupe:"The training is organized in small groups of up to 6 people",
         Shtese:"Additional data",
         ShembujKonkrete:"Concrete and real examples in companies in the field of trade, services, construction, etc",
         LegjislacionFiskal:"Njohja me legjislacionin fiskal në fuqi",
         ShpjegimIkoncepteve:"Explanation of basic accounting concepts, Labor Code, Legal Business Forms",
         AplkikimDheDeklarim:"Online applications and declarations (Sales Book, Purchase Book, calculation of VAT, Withholding Tax, Simplified Income Tax and Income Tax, Personal Income Tax)",
         GrupetEInteresit:"Interest groups",
         StudenteApoTePUInesuar:"Students or employees in the field of economics",
         StudenteTeDiplomuar:"Graduate students",
         KompaniPrivate:"Employed in private/public companies",
         BizneseTeVogle:"Small or medium business owners",
         MenaxherAdmin:"Manager/Administrator of large businesses",
         PersonaTeTjereNeFinance:"Other persons who have interests in the financial field",
         NePerfundimTeTrajnimit:`At the end of the training, what you get in return for the investment made in us is not only a recognized Certificate, but also the knowledge that will remain with you and will raise the "First Foundations of the Craft". For the best students of the course,
         we offer employment opportunities according to job offers to our Clients!`,
         BiznesiJuaj:`You are fully committed to your business. Your days are filled with decisions and concerns that affect your company.
         Sometimes business owners are so focused on the day-to-day tasks that they lose sight of the big picture, which is making a profit. A consultant
         financial will look at the overall state of your finances without involving emotions. They can bring not only professional knowledge
         to the table, but also present a different perspective on your financial matters.`,
         Stabilitet:"6. Stabilità e empowerment",
         KonsulenceFinanciareIkeshilluar:`A qualified financial consultant can clarify and help you focus on running your business on the right track.
         They have the tools and resources to employ tactics that will ensure your business grows and thrives.
         They can help you assess your goals and determine the best way to achieve them. it
         it is important because of future changes that will occur in markets, technology and other volatile circumstances.`,
         SiguriaEseArdhmes:"7. Security of the future",
         PlanifikimIvazhdimsisSeBiznesit:`Business continuity planning is another critical factor. How would a natural disaster affect it?
         What happens if you have a health emergency?
         Planning for emergencies or disasters is important to the longevity of your business, and a financial consultant can help you
         help develop a strategy where your business can continue to operate even if something unexpected happens.
         Finally, no matter what stage of business you're in, it's important to surround yourself with professionals who are
         experts in what you are not, so you can succeed, saving time.
         As a business we know that time is gold, so don't waste any more and contact us to get the service
         the best financial support your business deserves.`,
         Jurist:"Lawyer",
         KohaEpunes:"Full time lawyer",
         PlotesoFushat:"Fill in the fields below with your data and we will contact you.",
         Inxhinjer:"Engineer",
         InxhinjerMeKohetePlote:"Full time engineer",
         Koment:"Leave your comment:",
         Asistente:"Assistant",
         AsistenteMekoheTePlote:"Full time assistant",
         ofrontrajnim:`Parid Consulting offers training for:`,
         trajnimiavancuar:`Advanced training where several modules are offered:`,
         financa5:`Alpha Web and Finance 5 including: Assets, Inventory, Purchasing,
         Sales, Cash, Bank, Ledger, Opening of Accounts or Accounts
         as well as Construction of Financial Statements`,
         exelku:`Excel including: basic knowledge at economist level`,
         legjislascion:`Tax Legislation including: General concepts of
         VAT, Tax Procedure, Income Tax, Valuation
         or Tax Control as well as the Labor Code`,   
         efiling:`Declarations with efiling`, 
         llogaritjaepages:`Salary calculation according to company policy`,
         trajnimibaze1:`Basic training where only 4 modules are offered:`,
         AlphaWeb:`Alpha Web`,
         neofrojme:`We offer :`,
         certifikateelicensuar:`Certificate licensed by the Ministry of Finance and
         Economy.`,
         prakintensive:`Intensive practice.`,
         ulje10:`10% discount for students with Student Card. `,
         ulje50:`50% discount for students with average above 9.`,
         ulje50familje:`50% discount for students from families with economic assistance.`,
        },



    },
    al: {
      translation: {
        ulje50familje:`50% ulje për studentët nga familjet me ndihmë ekonomike.`,
        ulje50:`50% ulje për studentët me mesatare mbi 9.`,
        ulje10:`Ulje 10% për studentët me Kartë Studenti.`,
        certifikateelicensuar:`Çertifikatë të liçencuar nga Ministria e Financave dhe
        Ekonomisë.`,
        AlphaWeb:`Alpha Web`,
        trajnimibaze:`
        Self Care ku do të merrni njohuritë bazë mbi raportimet e
        fiskalizimit për të dhënat e njësisë së biznesit, operatorit,
        faturat e fiskalizuara, deklaratat doganore dhe kofigurimet kryesore
        të tatimpaguesit`,
        llogaritjaepages:`Llogaritja e pagave sipas politikës së kompanisë`,
        legjislascion:`Legjislacion Tatimor ku përfshihen: Konceptet e përgjithshme të
        TVSH-së, Proçedura Tatimore, Tatimi mbi të ardhurat, Vlerësim
        apo Kontroll Tatimor si dhe Kodi i Punës.`,
        exelku:`Excel ku përfshihen: njohuritë bazë në nivel ekonomisti`,
        financa5:`Alpha Web dhe Financa 5 ku përfshihen: Asetet, Inventari, Blerje,
        Shitje, Arka, Banka, Libri i madh, Çelje e Atikujve apo Llogarive
        si dhe Ndërtimi i Pasqyrave Financiare`,
        trajnimiavancuar:`Trajnimi i avancuar ku ofrohen disa module :`,
        ofrontrajnim:`Parid Consulting ofron trajnim për :`,
        trjanimetepergjithshme:`Trajnime të përgjithshme`,
        trajnimeprofesionale:`Trajnime profesionale dhe praktikë kontabiliteti`,
        komenti:`Lini komentin tuaj :`,
        Kartestudenti:`- Karte studenti -`,
        po:'Po',
        jo:'Jo',
        pikpamja:`Pikëpamja e Palës së Tretë`,
        edhepsepaguani:`Edhe pse do të paguani një konsulent financiar, kursimet afatgjata do të justifikojnë koston. 
        Konsulenti juaj financiar fokusohet në një gjë në lidhje me biznesin tuaj: paratë.
         Gjithçka ka të bëjë me marrjen e vendimeve më të zgjuara financiare për të rritur shanset tuaja për sukses.
          Ata mund t’ju udhëheqin drejt mundësive më të mira për të rritur fitimet tuaja.`,
        Kursimparash:`Kursim parash`,
        qellimijuaj:`Qëllimi juaj është të ndërtoni biznesin tuaj dhe të keni sukses, duke maksimizuar fitimin tuaj. Investimet e zgjuara janë kritike për arritjen e këtyre qëllimeve. 
        Shumica e pronarëve të bizneseve ose nuk kanë njohuritë ose kohën (ose të dyja) për të analizuar dhe vlerësuar mundësitë e investimit.
        Një konsulent financiar me kualifikimin e duhur dhe njohuri mund t’ju udhëheqë për investimet që janë të përshtatshme për biznesin tuaj. `,
        Investimi:`Investimi`,
        njeplanbiznesi:`Një plan biznesi është kritik për suksesin e biznesit tuaj. 
        Ju duhet të dini se ku po shkoni, si po arrini atje dhe çfarë të bëni nëse hasni pengesa gjatë rrugës.
         Një konsulent i mirë financiar mund të hartojë një plan gjithëpërfshirës për t’ju ndihmuar të drejtoni biznesin tuaj në mënyrë
          sa më efikase dhe të përgatiteni për problemet që lindin më vonë.`,
        Planifikimi:'Planifikimi',
        eshteekuptueshme:`Është e kuptueshme që dëshironi të kurseni para duke bërë vetë disa punë, por të merresh me financat kërkon njohuri dhe trajnime. Nëse nuk është fusha juaj, 
        ju mund t’i bëni biznesit tuaj më shumë dëm sesa mirë duke u përpjekur ta manaxhoni vetë atë.`,
        Njekonsulent:`Një konsulent financiar i aftë dhe me përvojë i njeh ligjet dhe metodat për t’ju ndihmuar të përfitoni sa më shumë nga financat
        e biznesit tuaj. 
       Ata mund të punojnë me ju për të rishikuar rregullisht situatën tuaj financiare për të parandaluar gabime serioze dhe për të 
       korrigjuar shpejt çdo vendim të gabuar lidhur me keqmenaxhimin e fluksit të parave.`,
        pershkrimi: `Ideuar shumë kohë më parë themelimit te saj ,Parid Consulting Shpk,
        vjen prej vitit 2017 si një ndër studiot e konsulencës
         më serioze në vend për ti qëndruar pranë nevojave të të gjithë klientëve dhe individëve që kërkojnë të arrijnë profesionalizmin.`,
        pyetjet: `Kush jemi ?`,
        kontakt: `Na kontakto`,
        lajme: `Lajme dhe evente`,
        sherbime: `Shërbimet`,
        konsulencefinanciare: `Konsulencë financiare dhe ligjore`,
        kontabiliteti: `Shërbime kontabiliteti`,
        konsulence: `Konsulencë bankare`,
        fiskalue: `Shërbime fiskale`,
        pasuria: `Vlerësime të pasurisë së paluajtshme`,
        burime: `Burime njerzore`,
        certifikime: `Çertifikime dhe trajnime`,
        Trajnime: "Trajnime profesionale",
        Shiko: `Shiko`,
        trajnimetepergjithshme: `Trajnime të përgjithshme`,
        Aplikim: `Aplikim për punë`,
        shteseligji: `Akti Normativ Nr. 27 – Për një shtesë në Ligjin Nr. 9920, datë 19.05.2008`,
        deklarimi: `Deklarimi i Tatimit të Thjeshtuar mbi Fitimin-Binzesin e Vogël`,
        deklarimtatimi: `Deklarimi i Tatimit mbi të Ardhurat Personale`,
        benefite: `7 benefite që biznesi juaj përfiton falë konsulencës financiare`,
        slideri: `Partnerët Tanë`,
        PerseParid: "Përse Parid Consulting?",
        RrethneshPershkrimi: `Ideuar shumë kohë më parë themelimit të saj, Parid Consulting Shpk, prej vitit 2017 vjen si një ndër studiot e konsulencës 
            më serioze në vend për ti qëndruar pranë nevojave të klientëve dhe individëve që kërkojnë të arrijnë profesionalizmin.
            Parid Consulting Shpk përbëhet nga një ekip profesionistësh të përkushtuar dhe pasionuar pas fushave përkatëse ku zotërojnë tituj të ndryshëm 
            profesionalë. Arritjet akademike si dhe eksperienca profesionale në role drejtuese ne sektorë bankar dhe studio 
            kontabiliteti, bëjnë të sjellim pranë jush një shërbim dinjitoz ku reflektohen vlerat dhe aftësitë tona.`,
        we: `Ne kujdesemi për biznesin tuaj që në hapat e parë duke ofruar analiza për projektet e deri në implementimin e tyre.`,
        studio: `Studioja ofron një paketë të plotë shërbimesh për subjektet vendase dhe të huaja si: konsulencë financiare, bankare dhe fiskale, plane biznesi, hapje aktiviteti, mbajtje kontabiliteti, analizë kredie, vlerësim të pasurisë së paluajtshme dhe trajnim të burimeve njerëzore. Synimi jonë është ngritja e një strukture për të mbështetur klientët tanë në punën dhe 
                            detyrat e tyre të përditshme duke ofruar shërbime të personalizuara dhe me vlerë të shtuar, në përshtatje me ndryshimet në kërkesat e tyre.`,
        offer: ` Puna serioze dhe dedikimi maksimal që kemi paraqitur te klientët
            tanë të kënaqur, ka bërë të rritemi në mënyrë progresive duke
            zgjeruar reputacionin tonë si një studio prestigjioze. Ne jemi
            partneri juaj i besueshëm që ofrojmë zgjidhjet më të mira
            profesionale duke synuar eficencën dhe efektivitetin në marrjen
            e vendimeve të duhura financiare.`,
        stafi: `Stafi`,
        drejtor: `Drejtor`,
        supervizor: `Supervizor`,
        supervizore: `Supervizore`,
        financier: `Financier`,
        financiere: `Financiere`,
        konsulencebankare: `Konsulencë bankare`,
        dukefiluarqenga: `Duke filluar qe nga konsulenca e përgjithshme ne lidhje me
            Kredine, përgatitjen e dokumentacionit, negociatat me bankat
            per kushtet me te mira deri ne lëvrimin e kredisë.`,
        konsulencenelidhje: ` Konsulence ne lidhje me Garancitë dhe letër kredit Bankare ,
            përgatitje dokumentacioni, negociata me bankat, etj.`,
        depozitakonsulenca: ` Depozita, konsulence per interesat me te mira ne treg dhe
            per bankat me te sigurta.`,
        cdollojkonsulence: `  Çdo lloj konsulence tjetër për shërbime bankare.`,
        RrethneshNav: "Rreth nesh",
        keninevojepersherbimintone: `Keni nevojë për shërbimin tonë?`,
        kontaktNav: "Kontakt",
        nakontaktoni: `Na kontaktoni në email ose plotësojini fushat e mëposhtme dhe ne do ju kontaktojmë.  `,
        SherbimetNav: "Shërbimet",

        futemrin: `Emri dhe Mbiemri`,
        numri: `Numri`,
        zgjidhninjesherbim: "Zgjidhni një shërbim",
        option1: `Konsulencë financiare dhe ligjore`,
        option2: `Shërbime kontabiliteti `,
        option3: `Konsulencë bankare`,
        option4: `Shërbime fiskale`,
        option5: `Vlersime të pasurisë të paluajtshme`,
        option6: `Burime njërzore`,
        dergo: `Dërgo`,
        Kontakto: `Kontakt`,
        futemail: `Email`,
        Subjekt: `Subjekt`,
        Mesazhiii: `Mesazhi`,
        pika1: ` Analizë dhe orientim për hapjen e bizneseve të reja.`,
        pika2: `  Studim financiar dhe fiskal për projektet që janë në fazën e
             nisjes (start up) duke sugjeruar format mete mira te
             financimit për aktivitetin e ri.`,
        pika3: `  Analize e projektit ne lidhje me hapjen e bizneseve te reja
             si dhe analizimi.I treguesve te sektorëve ne lidhje me
             rentabilitetin e tij.`,
        pika3: `  Analize e projektit ne lidhje me hapjen e bizneseve te reja
             si dhe analizimi.I treguesve te sektorëve ne lidhje me
             rentabilitetin e tij.`,
        pika4: ` Informimi dhe perditesimi i bizneseve ne lidhje me çeshtje
             te ndryshme qe lidhen me legjislacionin tatimor dhe zbatimin
             e tij.`,
        pika5: `  Përfaqësim në marrëdhëniet me QKR dhe QKL (regjistrim
                biznesi, ndryshim statusi, mbyllje aktiviteti etj)`,
        pika6: `   Përgatitje dokumentash për pjesëmarrje në tender (prokurime
                  publike).`,
        pika7: `  Asistojmë dhe përfaqësojmë ne procesin e ankimit pranë Zyrës
             se Apelimit Tatimor.`,
        pika8: `    Hartimin e dosjeve personale për çdo punëmarrës (Libreze
                pune, Libreze Sigurimesh etj)`,
        pika9: `  Kontrata te punës te punëmarrësve (kolektive apo
                        Individuale).`,
        pika10: `  Përgatitja e raporteve dhe vlerësimeve të ndryshme për
             zgjedhjen e menyres me fitimprurëse te investimeve të
             mundshme në të ardhmen..`,
        lista1: ` Deklarimet tatimore referuar legjislacionit në fuqi
        kryesisht për TVSH, Tatim në Burim, Tatim mbi të ardhurat,
        Listë-pagesa, Sigurime Shoqërore dhe Shëndetësore, Libra
        Blerje dhe Shitje si dhe deklarimi dhe largimi i punonjësve.`,

        RrethneshNav: "Rreth nesh",
        kontaktNav: "Kontakt",
        SherbimetNav: "Shërbimet",
        dropDownRrethParid: "Rreth ''Parid Consulting''",
        stafiYne: "Stafi ynë",
        SherbimKontabelDropNav: "Shërbime Kontabiliteti",
        KonsulenceFinanceDropDown: "Konsulencë Financiare dhe Ligjore",
        KonsulenceBankareDropNav: "Konsulencë Bankare",
        KonsulenceFiskaleDropNav: "Shërbime Fiskale",
        VleresimIpasuriveDropNav: "Vlerësim të Pasurisë së Paluajtshme",
        SherbimKontabiliteti: "Shërbime kontabiliteti",
        RaporteDheAnaliza:
          "Raporte dhe analiza të ndryshme për qëllime të vendimmarrjes.",
        PergatitjeTeRaporteve:
          "Përgatitje të raporteve financiare dhe kontabël në lidhje me performancen dhe ecurinë e biznesit tuaj.",
        MbajtjaEploteEllogaritjeve: "Mbajtja e plotë e llogarive kontabiliste.",
        PërgatitjeTëPasqyraveFfinanciare:
          "Përgatitje të pasqyrave financiare vjetore si dhe mujore në përputhje me Standardet Kombëtare dhe Ndërkombëtare të Kontabilitetit.",
        NdjekjeEprocedurave:
          "Ndjekje e procedurave kontabiliste të Likuidimit, mbylljes dhe falimentimit te Shoqerisë.",
        astudio: `Parid Consulting në bashkëpunim me “A STUDIO”, ofron një
             shërbim të personalizuar për të mbështetur klientët tanë me
             shërbimet e mëposhtme:`,
        apartamente: `Apartamente`,
        komerciale: `Njësi komerciale`,
        hotel: "Hotel",
        Hec: `HEC`,
        traull: `Trull`,
        vlersimtelinjave: `Vlerësim të linjave teknologjike dhe makineri`,
        automjete: `Automjete të lehta dhe të rënda.`,
        lista2: ` Ndjekja e procedurave gjatë kontrolleve të ndryshme
             tatimore.`,
        lista3: ` Asistencë e vazhdueshme për personelin përgjegjës.`,
        lista4: `   Rishikim i deklarimeve tatimore periodike.`,
        lista5: `    Administrim, përfaqësime në lidhje me mosmarrëveshjet me
             administratën tatimore.`,
             AplikimPerPune:'Apliko për punë',
             LlojiIpunes:'Financier/e',
             PershkrimiIpunes:"Financier/e me kohë të plotë ",
             DetajetPerPunen:"Plotësojini fushat e mëposhtme me të dhënat tuaja dhe ne do ju kontaktojmë.",
             NgarkoCV:"Ngarko CV tende",
        Gjuhet:"Gjuhët",
        Nekreun:`Në kreun XV, pas pikës 6, të nenit “Dispozita kalimtare”, të ligjit nr. 9920, datë 19.5.2008,
        “Për procedurat tatimore”, të ndryshuar, shtohet pika 7, me këtë përmbajtje:
        “7. Përjashtimisht parashikimeve në këtë ligj, për kundërvajtjet administrative që lidhen me
        afatet e hyrjes në fuqi dhe zbatimin efektiv të ligjit nr. 87/2019, “Për faturën dhe sistemin e
        monitorimit të qarkullimit”, të ndryshuar, zbatimi i sanksioneve do të fillojë të aplikohet pas
        datës 1 janar 2022.”`,
        njebiznes:`Një biznes ka shumë përgjegjësi dhe shpenzime dhe sigurisht që nuk keni nevojë për të paguar fatura ekstra.
        Sipas ekspertëve mbi 60% e pronarëve të bizneseve nuk kanë një konsulent financiar.     
       Nëse jeni një prej tyre, mund të merrni një rrezik të madh për të ardhmen e biznesit tuaj dhe veten tuaj.
   
       Por përse ndodh kjo, dhe çfarë dobish vijnë bashkë më një konsulent financiar?

        Drejtimi i një biznesi është plot sfida. Gjithmonë krijohen situata që nuk shkojnë siç duhet, 
        ndaj ju do donit të siguroheshit që të minimizoni pasojat. Keqmenaxhimi i parave, problemet e fluksit të parave,
         pagesat me vonesë, çështjet e tatimeve dhe problemet e tjera financiare mund të jenë mjaft kritike për të mbyllur një biznes.
          Kjo është arsyeja pse është kaq e rëndësishme të kontrolloni aspektet financiare të biznesit tuaj. Punësimi i një konsulenti 
          financiar me eksperiencën e duhur mund të parandalojë që biznesi juaj të shkojë drejt falimentimit.
        Ka shumë mënyra se si një konsulent financiar i kualifikuar mund të jetë partneri juaj për të ndihmuar biznesin 
        tuaj të lulëzojë.`,
        Njohuri:'Njohuri',
        perfundonstudimet:`Po përfundon studimet në fushën e ekonomisë dhe ende nuk e ke të qartë se çfarë të kërkohet në tregun e punës?`,
        ndoshtajediplomuar:`Apo ndoshta je diplomuar por nuk ke filluar akoma të punosh në profilin tënd?`,
        kursetrajnimine:`Parid Consulting ofron kurse trajnimi profesionale në fushën e EKONOMISË`,
        TrajnimetNeFinance:"Ju përgatisim si financiere në PRAKTIKË",
        TrajnimeNeAlphaDheFinance:"Ofrojmë trajnime intensive në programin Alpha Web dhe Financa 5",
        DeklarimetOnline:"Ju mësojmë si të menaxhoni periodikisht një subjekt nga ana Financiare duke përfshirë këtu Deklarimet Online deri tek Bilanci Vjetor",
        TrajnimeMeEksperte:"PARID CONSULTING organizon trajnimin me lektorë si Ekspertë Kontabël, Kontabilist të Miratuar dhe Ekspertë Fiskal",
        ProgrmaiExcelIperfshire:"Program i paketës Office EXCEL i përfshirë në çmimin e paketës",
        UljePerStudente:"Ulje 10% për studentët me kartë student,",
        TrajnimetOrganizohenNe6Grupe:"Trajnimi organizohet në grupe te vogla deri në 6 persona",
        Shtese:"Të dhënat shtesë",
        ShembujKonkrete:"Shembuj konkrete dhe real në kompani në fushën e tregtisë, shërbimeve, ndërtimit etj",
        LegjislacionFiskal:"Njohja me legjislacionin fiskal në fuqi",
        ShpjegimIkoncepteve:"Shpjegimi i koncepteve bazë të kontabilitetit, Kodit te Punës, Format Ligjore të Biznesit",
        AplkikimDheDeklarim:"Aplikimet dhe deklarimet online (Libri i Shitjes, Libri Blerjes, llogaritja e T.V.SH-së, Tatimit në burim, Tatim Fitim i Thjeshtuar dhe Tatim Fitim, Tatimi mbi të ardhurat personale)",
        GrupetEInteresit:"Grupet e interesit",
        StudenteApoTePUInesuar:"Studentë apo të punësuar në fushën e ekonomisë",
        StudenteTeDiplomuar:"Studentë të diplomuar",
        KompaniPrivate:"Të punësuar në kompani private/publike",
        BizneseTeVogle:"Pronarë të bizneseve të vogla ose të mesme",
        MenaxherAdmin:"Menaxher/Administrator të bizneseve të mëdha",
        PersonaTeTjereNeFinance:"Persona të tjerë që kanë interesa në fushën financiare",
        NePerfundimTeTrajnimit:`Në përfundim të trajnimit, ajo çfarë merrni mbrapsht investimit të bërë tek ne nuk është vetëm një Certifikatë e njohur, por edhe njohuritë që do t’ju ngelen e do të ngrenë ”Themelet e para të Zanatit”. Për studentët më të mirë të kursit,
        ofrojmë mundësi punësimi sipas ofertave të punës tek Klientët tanë!`,
        BiznesiJuaj:`Ju jeni plotësisht të përkushtuar në biznesin tuaj. Ditët tuaja janë të mbushura me vendime dhe shqetësime që prekin kompaninë tuaj.
        Ndonjëherë pronarët e bizneseve janë aq të përqendruar në punët e përditshme saqë humbasin pamjen e madhe, që është të fitojnë. Një konsulent
        financiar do të shikojë gjendjen e përgjithshme të financave tuaja pa përfshirë emocionet. Ata mund të sjellin jo vetëm njohuritë profesionale
        në tryezë, por edhe të paraqesin një perspektivë ndryshe për çështjet tuaja financiare.`,
        Stabilitet:"6.Stabiliteti dhe fuqizimi",
        KonsulenceFinanciareIkeshilluar:`Një konsulent financiar i kualifikuar mund t’ju qartësojë dhe t’ju ndihmojë të përqendroheni në drejtimin e biznesit tuaj në rrugën e duhur.
        Ata kanë mjetet dhe burimet për të përdorur taktika që do të sigurojnë që biznesi juaj të rritet dhe të lulëzojë. 
        Ata mund t’ju ndihmojnë të vlerësoni qëllimet tuaja dhe të përcaktoni rrugën më të mirë për t’i arritur ato. Kjo
        është e rëndësishme për shkak të ndryshimeve të ardhshme që do të ndodhin në tregje, teknologji dhe rrethana të tjera të paqëndrueshme.`,
        SiguriaEseArdhmes:"7.Siguria e së ardhmes",
        PlanifikimIvazhdimsisSeBiznesit:`Planifikimi i vazhdimësisë së biznesit është një tjetër faktor kritik. Si do të ndikonte një fatkeqësi natyrore?
        Çfarë ndodh nëse keni një emergjencë shëndetësore?
        Planifikimi i emergjencave apo fatkeqësive është i rëndësishëm për jetëgjatësinë e biznesit tuaj dhe një konsulent financiar mund t’ju 
        ndihmojë të zhvilloni një strategji ku biznesi juaj mund të vazhdojë të operojë edhe nëse ndodh diçka e papritur.    
        Së fundi, pa marrë parasysh se në cilën fazë të biznesit jeni, është e rëndësishme të rrethoheni me profesionistë që janë
        ekspertë në atë që ju nuk jeni, në mënyrë që të keni sukses, duke kursyer kohë.
        Si një biznes ne e dimë që koha është flori, ndaj mos e humbisni më shumë dhe na kontaktoni për të marrë shërbimin
        më të mirë financiar që biznesi juaj meriton.`,
        Jurist:"Jurist/E",
        KohaEpunes:"Jurist/E me kohe te plotë",
        PlotesoFushat:"Plotësojini fushat e mëposhtme me të dhënat tuaja dhe ne do ju kontaktojmë.",
        Inxhinjer:"Inxhinier/e",
        InxhinjerMeKohetePlote:"Inxhinier/e me kohë të plotë",
        Koment:"Lini komentin tuaj :",
        Asistente:"Asistent/e",
        AsistenteMekoheTePlote:"Asistent/e me kohë të plotë",
        efiling:`Deklarimet me efiling`,
        trajnimibaze1:`Trajnimi bazë ku ofrohen vetëm 4 module:`,
        Financapesa:`Financa 5`,
        legjtatimor:`Legjislacioni Tatimor`,
        neofrojme:`Ne ofrojmë :`,
        prakintensive:`Praktikë intensive.`,
        studentetmetemire:`Për studentët më të mirë të kursit, mundësi punësimi sipas
        ofertave të punës tek klientët tanë.`,
        apliko:'Apliko',
        ketumund:'Këtu mund të jeni ju!',
      },
    },




    it: {
      translation: {
        neofrojme:`Noi offriamo :`,
        legjtatimor:`Legislazione fiscale`,
        trajnimibaze1:`Formazione di base in cui vengono offerti solo 4 moduli:`,
        trajnimibaze:`
        'Self Care' dove otterrai le conoscenze di base sulla segnalazione
         tassazione per i dati del ramo d'azienda, dell'operatore,
         fatture fiscalizzate, dichiarazioni doganali e configurazioni anagrafiche
         del contribuente`,
        efiling:`Dichiarazioni con efiling`,
        legjislascion:`Legislazione fiscale, tra cui: Concetti generali di
        IVA, Procedura Tributaria, Imposta sui Redditi, Estimo
        o il controllo fiscale nonché il codice del lavoro`,
        exelku:`Excel compreso: conoscenza di base a livello di economista`,
        financa5:`Alpha Web and Finance 5 include: Beni, Inventario, Acquisti,
        Vendite, contanti, banca, libro mastro, apertura di conti o conti
        così come la costruzione del bilancio`,
        trajnimiavancuar:`Formazione avanzata in cui vengono offerti diversi moduli:`,
        ofrontrajnim:`Parid Consulting offre formazione per :`,
        trjanimetepergjithshme:`Allenamento generale`,
        kursetrajnimine:`Parid Consulting offre corsi di formazione professionale nel campo dell'ECONOMIA`,
        trajnimeprofesionale:`Formazione professionale e pratica contabile`,
        komenti:`Lascia il tuo commento :`,
        Kartestudenti:`- Carta dello studente -`,
        po:'Si',
        jo:'No',
        pikpamja:`Visualizzazione di terze parti`,
        edhepsepaguani:`Anche se pagherai un consulente finanziario, i risparmi a lungo termine giustificheranno il costo.
        Il tuo consulente finanziario si concentra su una cosa della tua attività: il denaro.
         Si tratta di prendere le decisioni finanziarie più intelligenti per aumentare le tue possibilità di successo.
          Possono guidarti verso le migliori opportunità per aumentare i tuoi guadagni.`,
        Kursimparash:`Risparmiare soldi`,
        qellimijuaj:`Il tuo obiettivo è costruire la tua attività e avere successo, massimizzando i tuoi profitti. Gli investimenti intelligenti sono fondamentali per raggiungere questi obiettivi.
        Alla maggior parte degli imprenditori mancano le conoscenze o il tempo (o entrambi) per analizzare e valutare le opportunità di investimento.
        Un consulente finanziario con le giuste qualifiche e conoscenze può guidarti verso gli investimenti giusti per la tua attività.`,
        Investimi:`Investire`,
        njeplanbiznesi:`Un business plan è fondamentale per il successo della tua attività.
        Devi sapere dove stai andando, come ci arrivi e cosa fare se incontri ostacoli lungo il percorso.
         Un buon consulente finanziario può progettare un piano completo per aiutarti a gestire la tua attività nel modo giusto
          nel modo più efficiente possibile e prepararsi per i problemi che sorgono in seguito.`,
        Planifikimi:'Pianificazione',
        eshteekuptueshme:`È comprensibile che tu voglia risparmiare facendo un po' di lavoro da solo, ma gestire le finanze richiede conoscenza e formazione. Se non è il tuo campo,
         potresti fare più male che bene ai tuoi affari cercando di gestirli da solo.`,
        Njekonsulent:`Un consulente finanziario qualificato ed esperto conosce le leggi ei metodi per aiutarti a ottenere il massimo dalle tue finanze.
        della tua attività.
       Possono lavorare con te per rivedere regolarmente la tua situazione finanziaria per prevenire gravi errori e per
       correggere rapidamente eventuali decisioni errate relative alla cattiva gestione del flusso di cassa.`,
        pershkrimi: `Ideata prima della sua fondazione, Parid Consulting S.R.L, dal 2017
                        si presenta come uno dei più affidabile studi di consulenza nel paese per soddisfare le
                        esigenze dei clienti e delle persone che cercano di raggiungere la professionalità.`,
        pyetjet: `Chi siamo ?`,
        kontakt: `Un contatto`,
        lajme: `Notizie ed Eventi`,
        sherbime: `Servizi`,
        konsulencefinanciare: `Consulenza finanziaria e legale`,
        kontabiliteti: `Servizi contabili`,
        konsulence: `Consulenza bancaria`,
        fiskalue: `Servizi fiscali`,
        pasuria: `Valutazioni immobiliari`,
        burime: `Risorse umane`,
        certifikime: `Formazione professionale`,
        Trajnime: "Formazione professionale",
        Shiko: `Detagli`,
        trajnimetepergjithshme: `Formazione generale`,
        Aplikim: `Lavora  con noi`,
        shteseligji: `Atto normativo n. 27 – Ad integrazione della Legge n. 9920 del 19.05.2008`,
        deklarimi: `Dichiarazione Irpef Semplificata - Piccola Impresa`,
        deklarimtatimi: `Dichiarazione di imposta sul reddito delle persone fisiche`,
        benefite: `7 vantaggi che la tua azienda trae dalla consulenza finanziaria`,
        slideri: `I nostri partner`,
        PerseParid: "Perché Parid Consulting?",
        RrethneshPershkrimi: ` Ideata prima della sua fondazione, Parid Consulting S.R.L, dal
            2017 si presenta come uno dei più affidabile studi di
            consulenza nel paese per soddisfare le esigenze dei clienti e
            delle persone che cercano di raggiungere la professionalità.
            Parid Consulting S.R.L. ha un team di professionisti dedicati
            e appassionati in diversi settori in cui possiedono diversi
            titoli professionali. Con i risultati accademici e
            l’esperienza professionale negli studi bancari e contabili,
            offriamo un servizio dignitoso che riflette i nostri valori e
            capacità.`,
        we: ` Noi ci occupiamo per la sua azienda dai primi passi offrendo
            l’analisi del progetto fino alla loro implementazione.`,
        studio: `  Il studio offre una gamma completa di servizi per entità
            nazionali ed estere: consulenza finanziaria, bancaria e fiscale,
            piani di business, aperture di attività, contabilità, analisi
            del credi, valutazione immobiliare e formazione delle risorse
            umane. Nostro obiettivo è creare una struttura per supportare i
            nostri clienti nel loro lavoro e attività quotidiane offrendo
            servizi personalizzati.`,
        offer: `      Il serio lavoro e la dedicazione dimostrata ai nostri clienti
            soddisfatti, hanno fatto espandere progressivamente la nostra
            reputazione di un prestigioso studio. Noi siamo il vostro
            partner affidabile che offriamo le migliori soluzioni
            professionali incentrate all’efficienza e all’efficacia per
            prendere le giuste decisioni finanziarie.`,
        stafi: `Il nostro team`,
        drejtor: `Direttore`,
        supervizor: `Supervisor`,
        supervizore: `Supervisore`,
        financier: `Finanziario`,
        financiere: `Finanziaria`,
        konsulencebankare: `Consulenza bancaria`,
        dukefiluarqenga: `Consulenza generale per il prestito, preparazione della
            documentazione, trattative con lebanche per le migliori
            condizioni di erogazione del prestito.`,
        konsulencenelidhje: ` Consulenza su garanzie bancarie e lettere di credito,
            preparazione della documentazione, trattative con le banche,
            ecc.`,
        depozitakonsulenca: ` Depositi, consulenza per il miglior interesse del mercato e
           per le banche più sicure.`,
        cdollojkonsulence: `  Qualsiasi altro servizio di consulenza bancaria.`,
        keninevojepersherbimintone: ` Hai bisogno del nostro servizio?`,
        nakontaktoni: `Contattaci via email o compila i campi sottostanti e vi
           contatteremo.`,
        futemrin: `Nome e Cognome`,
        numri: `Telefono`,
        zgjidhninjesherbim: "selezionare un servizio",
        option1: `Consulenza finanziaria e legale`,
        option2: `Servizi contabili`,
        option3: `Consulenza bancaria`,
        option4: `Servizi fiscali`,
        option5: `Valutazioni immobiliari`,
        option6: `Risorse umane`,
        dergo: `Invia`,
        Kontakto: `Contatto`,
        futemail: `E-mail`,
        Subjekt: `Argomento`,
        Mesazhiii: `Messaggio`,
        pika1: `  Analisi e orientamento per l’avvio di nuove imprese.`,
        pika2: ` Studio finanziario e fiscale per l’avvio di progetti che
            suggeriscono le migliori forme di finanziamento.`,
        pika3: `   Analisi dei progetti e degli indicatori finanziari relativi
            alla redditività delle nuove imprese`,
        pika4: `   Informare e aggiornare le imprese su varie questioni
            relative alla legislazione fiscale e alla sua attuazione.`,
        pika5: ` Rappresentanza nelle relazioni con QKR e QKL (registrazione
                delle imprese, cambiamento di status, chiusura, ecc.)`,
        pika6: `  Preparazione dei documenti per la gara d’appalto (appalti
                pubblici).`,
        pika7: `   Assistere e rappresentare i nostri clienti nel processo di
             appello presso l’Ufficio dei ricorsi fisca.`,
        pika8: `     Preparazione dei contratti di lavoro (collettivi o
                individuali).`,

        pika9: `   Compilazione di schede personali per ogni dipendente (Libri
                di lavoro, libretti assicurativi, ecc).`,
        pika10: `   Preparare varie relazioni e valutazioni per scegliere il
             modo più redditizio di possibili investimenti futuri..`,
        astudio: `  Parid Consulting in collaborazione con "A STUDIO", offre un
             servizio personalizzato per supportare i nostri clienti con i
             seguenti servizi:`,
        apartamente: `Appartamenti`,
        komerciale: `Unità Commerciale`,
        hotel: "Hotel",
        Hec: `Centrali idroelettriche`,
        traull: `Terra`,
        vlersimtelinjave: `Valutazione delle linee tecnologiche e dei macchinari`,
        lista1: `Dichiarazioni fiscali riferite alla normativa vigente
             principalmente in materia di IVA, ritenute alla fonte,
             imposte sul reddito, imposte sui salari, assicurazioni
             sociali e sanitarie, libri d’acquisto e di vendita, nonché
             dichiarazione e allontanamento dei dipendenti.`,
        lista2: `Seguire le procedure durante le varie verifiche fiscali.`,
        lista3: ` Supporto continuo da parte del personale responsabile.`,
        lista4: ` Revisione delle dichiarazioni fiscali periodiche.`,
        lista5: `  Amministrazione, rappresentanza in caso di controversie con
             l’amministrazione fiscale.`,

        RrethneshNav: "Intorno a noi",
        kontaktNav: "Contatto",
        SherbimetNav: "Servizio",
        dropDownRrethParid: "L'azienda ''Parid Consulting''",
        stafiYne: "Nostro Team",
        SherbimKontabelDropNav: "Servizi Contabili",
        KonsulenceFinanceDropDown: "Consulenza finanziaria e legale",
        KonsulenceBankareDropNav: "Consulenza bancaria",
        KonsulenceFiskaleDropNav: "Servizi Fiscali",
        VleresimIpasuriveDropNav: "Vulutazioni immobiliari",
        SherbimKontabiliteti: "Servizi contabili",
        RaporteDheAnaliza: "Rapporti e analisi diversi per scopi decisionali.",
        PergatitjeTeRaporteve:
          "Preparazione di relazioni finanziarie e contabili in relazione all'andamento e all'andamento della vostra attività.",
        MbajtjaEploteEllogaritjeve: "Contabilità completa.",
        PërgatitjeTëPasqyraveFfinanciare:
          "Predisposizione di bilanci annuali e mensili in conformità ai Principi Contabili Nazionali ed Internazionali.",
        NdjekjeEprocedurave:
          "Follow-up delle procedure contabili di liquidazione, liquidazione e fallimento della Società.",
        automjete: `Veicoli leggeri e pesanti.`,
        AplikimPerPune:'Lavora Con Noi ',
        LlojiIpunes:"Finanziaria",
        PershkrimiIpunes:"Finanziatore a tempo pieno",
        DetajetPerPunen:"Compila i campi sottostanti con i tuoi dati e verrai ricontattato.",
        Gjuhet:"La Lingua",
        NgarkoCV:"Carica il tuo CV:",
        Nekreun:`Al capo XV, dopo il comma 6, dell'articolo “Disposizioni transitorie”, della legge n. 9920 del 19.5.2008,
        "Sulle procedure tributarie", modificato, è aggiunto il punto 7, con il seguente contenuto:
        "7. Salvo quanto previsto dalla presente legge, per gli illeciti amministrativi connessi all'art
        i termini di entrata in vigore e di effettiva attuazione della legge n. 87/2019, "Sulla fattura e sul sistema di
        monitoraggio del traffico", modificato, l'attuazione delle sanzioni inizierà ad essere applicata dopo
        il 1° gennaio 2022."`,
        njebiznes: `Un'azienda ha molte responsabilità e spese e ovviamente non è necessario pagare bollette extra.
          Secondo gli esperti, oltre il 60% degli imprenditori non ha un consulente finanziario.
         Se sei uno di loro, potresti correre un grosso rischio per il futuro della tua attività e per te stesso.
         Ma perché questo accade e quali vantaggi si ottengono con un consulente finanziario?
          Gestire un'impresa è pieno di sfide. Ci sono sempre situazioni che non vanno bene,
          quindi vuoi assicurarti di ridurre al minimo le conseguenze. Cattiva gestione del denaro, problemi di flusso di cassa,
           pagamenti in ritardo, questioni fiscali e altri problemi finanziari possono essere abbastanza critici da chiudere un'attività.
            Questo è il motivo per cui è così importante controllare gli aspetti finanziari della tua attività. Assumere un consulente
            un consulente finanziario con la giusta esperienza può evitare che la tua azienda fallisca.
           Ci sono molti modi in cui un consulente finanziario qualificato può essere il tuo partner commerciale
          tuo a fiorire.`,
          Njohuri:'Conoscenza',
          perfundonstudimet:`Stai finendo gli studi nel campo dell'economia e non sai ancora cosa aspettarti nel mercato del lavoro?`,
          ndoshtajediplomuar:`O forse ti sei laureato ma non hai ancora iniziato a lavorare sul tuo profilo?`,
          TrajnimetNeFinance:"Ti prepariamo come finanziere in PRATICA",
          TrajnimeNeAlphaDheFinance:"Offriamo una formazione intensiva nel programma Alpha Web e Finance 5",
          DeklarimetOnline:"Ti insegniamo come gestire periodicamente una materia dal lato finanziario, compresi gli estratti conto online fino al bilancio annuale",
          TrajnimeMeEksperte:"PARID CONSULTING organizza corsi di formazione con docenti quali Esperti Contabili, Dottori Commercialisti ed Esperti Fiscali",
          ProgrmaiExcelIperfshire:"Programma pacchetto Office EXCEL incluso nel prezzo del pacchetto",
          UljePerStudente:"Sconto del 10% per gli studenti con tessera dello studente",
          TrajnimetOrganizohenNe6Grupe:"La formazione è organizzata in piccoli gruppi di massimo 6 persone",
          Shtese:"Dati aggiuntivi",
          ShembujKonkrete:"Esempi concreti e reali in aziende del settore commercio, servizi, edilizia, ecc",
          LegjislacionFiskal:"Acquaintance with the fiscal legislation in force",
          ShpjegimIkoncepteve:"Spiegazione dei concetti contabili di base, Codice del lavoro, Moduli aziendali legali",
          AplkikimDheDeklarim:"Istanze e dichiarazioni on line (Libretto Vendite, Libretto Acquisti, Calcolo IVA, Ritenuta d'acconto, IRPEF e IRPEF, Irpef)",
          GrupetEInteresit:"Gruppi di interesse",
          StudenteApoTePUInesuar:"Studenti o dipendenti nel campo dell'economia",
          StudenteTeDiplomuar:"Studente laureato",
          KompaniPrivate:"Impiegato in aziende private/pubbliche",
          BizneseTeVogle:"Piccoli o medi imprenditori",
          MenaxherAdmin:"Gestore/Amministratore di grandi aziende",
          PersonaTeTjereNeFinance:"Altre persone che hanno interessi nel campo finanziario",
          NePerfundimTeTrajnimit:`Al termine della formazione, ciò che ottieni in cambio dell'investimento fatto in noi non è solo un Certificato riconosciuto, ma anche la conoscenza che rimarrà con te e innalzerà le "Prime Fondamenta del Mestiere". Per i migliori studenti del corso,
          offriamo opportunità di lavoro in base alle offerte di lavoro ai nostri Clienti!`,
          BiznesiJuaj:`Sei pienamente impegnato nella tua attività. Le tue giornate sono piene di decisioni e preoccupazioni che riguardano la tua azienda.
          A volte gli imprenditori sono così concentrati sulle attività quotidiane da perdere di vista il quadro generale, ovvero realizzare un profitto. Un consulente
          financial esaminerà lo stato generale delle tue finanze senza coinvolgere le emozioni. Possono portare non solo conoscenze professionali
          al tavolo, ma presenta anche una prospettiva diversa sulle tue questioni finanziarie.`,
          Stabilitet:"6. Stabilità e empowerment",
          KonsulenceFinanciareIkeshilluar:`Un consulente finanziario qualificato può chiarire e aiutarti a concentrarti sulla gestione della tua attività sulla strada giusta.
          Hanno gli strumenti e le risorse per impiegare tattiche che garantiranno alla tua azienda di crescere e prosperare.
          Possono aiutarti a valutare i tuoi obiettivi e determinare il modo migliore per raggiungerli. esso
          è importante a causa dei cambiamenti futuri che si verificheranno nei mercati, nella tecnologia e in altre circostanze volatili.`,
          SiguriaEseArdhmes:"7. Sicurezza del futuro",
          PlanifikimIvazhdimsisSeBiznesit:`La pianificazione della continuità operativa è un altro fattore critico. In che modo un disastro naturale lo influenzerebbe?
          Cosa succede in caso di emergenza sanitaria?
          La pianificazione di emergenze o disastri è importante per la longevità della tua attività e un consulente finanziario può aiutarti
          aiuta a sviluppare una strategia in cui la tua azienda può continuare a operare anche se accade qualcosa di inaspettato.
          Infine, indipendentemente dalla fase in cui ti trovi, è importante circondarti di professionisti che lo siano
          esperti in ciò che non sei, così puoi avere successo, risparmiando tempo.
          Come azienda sappiamo che il tempo è oro, quindi non sprecare altro e contattaci per ottenere il servizio
          il miglior supporto finanziario che la tua azienda merita.`,
          Jurist:"Avvocatessa",
          KohaEpunes:"Avvocato a tempo pieno",
          PlotesoFushat:"Compila i campi sottostanti con i tuoi dati e verrai ricontattato.",
          Inxhinjer:"Ingegnere",
          InxhinjerMeKohetePlote:"Ingegnere a tempo pieno",
          Koment:`Commento: "Lascia il tuo commento :`,
          Asistente:"Assistente",
          AsistenteMekoheTePlote:"Assistente a tempo pieno"
,
          llogaritjaepages:`Calcolo dello stipendio secondo la politica aziendale`,
          AlphaWeb:`Alpha Web`,
          Financapesa:`Financa 5`,
          certifikateelicensuar:`Certificato concesso in licenza dal Ministero delle Finanze e
          Economia.`,
          prakintensive:`Pratica intensiva`,
          ulje10:`Sconto del 10% per gli studenti con Carta dello Studente.`,
          ulje50:`Sconto del 50% per studenti con media superiore a 9.`,
          ulje50familje:`Sconto del 50% per studenti provenienti da famiglie con assistenza economica.`,
          studentetmetemire:`Per i migliori studenti del corso, opportunità di lavoro secondo l'art
          offerte di lavoro ai nostri clienti.`,
          apliko:'Applicare', 
           ketumund:'Ecco, puoi essere tu!',
      },
    },
  },
  lng: localStorage.getItem("lng") || "al",
});
