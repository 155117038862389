import React from "react";
import { FreeMode } from "swiper";
import {Swiper,SwiperSlide} from "swiper/react";
import 'swiper/css'
import 'swiper/css/free-mode'
import el1 from '../../../assetes/Photo/el1.png'
import el2 from '../../../assetes/Photo/el2.png'
import el3 from '../../../assetes/Photo/el3.png'
import el4 from '../../../assetes/Photo/el4.png'
import el5 from '../../../assetes/Photo/el5.png'
import el6 from '../../../assetes/Photo/el6.png'



import "swiper/css";
import "swiper/css/navigation";
import 'swiper/swiper-bundle.css';
import { useInView } from 'react-intersection-observer'
import { Pagination, Navigation } from "swiper";
import { useTranslation } from 'react-i18next'
const Slideri = ({english,shqip,italian}) => {
  const {t,i18n}=useTranslation()
  const {ref:homeRef1,inView:homeVisible1}=useInView();

  return (

  <div ref={homeRef1} className='conatiner sliderr-padding py-20  justify-center rounded-3xl bg-slate-50 '>
          
             <div className='flex justify-center mb-8 '>
   
               <p className="text2">  {t('slideri')}</p>
 
             </div>
 
                
              <Swiper 
              
                freeMode={true}
                grabCursor={true}
                navigation={true} 
                modules={[Navigation]}
              breakpoints={{
                0:{
                  slidesPerView: 1,
                  // spaceBetween:10,
                },
                480:{
                  slidesPerView: 2,
                },
                768:{
                  slidesPerView: 3,
                },
                1024:{
                  slidesPerView: 4,
                },
                1280:{
                  slidesPerView: 5,
                
                },

              }}
                >
                    <SwiperSlide   className={`${'rocket'} ${homeVisible1 ? 'animate-text  ' :''}`}>
                      <img src={el1 } className='w-48 h-48' alt=''/>
                    </SwiperSlide>
                    <SwiperSlide   className={`${'rocket'} ${homeVisible1 ? 'animate-text  ' :''}`}>
                    <img src={el2 } className='w-48 h-48' alt=''/>
                    </SwiperSlide>
                    <SwiperSlide   className={`${'rocket'} ${homeVisible1 ? 'animate-text  ' :''}`}>
                    <img src={el3 } className='w-48 h-48' alt=''/>
                    </SwiperSlide>
                    <SwiperSlide   className={`${'rocket'} ${homeVisible1 ? 'animate-text  ' :''}`}>
                    <img src={el4 } className='w-48 h-48' alt=''/>
                    </SwiperSlide>
                    <SwiperSlide   className={`${'rocket'} ${homeVisible1 ? 'animate-text  ' :''}`}>
                    <img src={el5 } className='w-48 h-48' alt=''/>
                    </SwiperSlide>
                    <SwiperSlide   className={`${'rocket'} ${homeVisible1 ? 'animate-text  ' :''}`}>
                    <img src={el6 } className='w-48 h-48' alt=''/>
                    </SwiperSlide>
                </Swiper>
               
            </div>

  )
}

export default Slideri