import React, { useEffect } from 'react';

import fotoahmeti from '../../assetes/stafi/fotoahmeti.jpeg'
import fotoilva from '../../assetes/stafi/fotoilva.jpeg'
import jusi from '../../assetes/stafi/jusi.jpeg'
import anxhi from '../../assetes/stafi/anxhi.jpeg'
import rigersa from '../../assetes/stafi/rigersa.jpeg'
import Kledi from '../../assetes/stafi/Kledi.jpeg'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
const PartneretTane = () => {
 

  return (
    <>
    <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
      <SwiperSlide>Slide 1</SwiperSlide>
      <SwiperSlide>Slide 2</SwiperSlide>
      <SwiperSlide>Slide 3</SwiperSlide>
      <SwiperSlide>Slide 4</SwiperSlide>
      <SwiperSlide>Slide 5</SwiperSlide>
      <SwiperSlide>Slide 6</SwiperSlide>
      <SwiperSlide>Slide 7</SwiperSlide>
      <SwiperSlide>Slide 8</SwiperSlide>
      <SwiperSlide>Slide 9</SwiperSlide>
    </Swiper>
  </>
  );
};

export default PartneretTane;
