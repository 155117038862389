import React from 'react'
import whiteLogoja from '../../assetes/whiteLogoja.png'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div  className=' static bottom grid justify-center   text-white  container max-w-full '>
  
             <div className='xl:flex lg:flex md:flex sm:grid gap-72 mx-auto'>
             
              <div className='flex flex-col  xl:items-start lg:items-start md:items-center sm:items-center my-6 '>
                <p className='my-2 text-lg '>Na kontaktoni në  </p>
                <address>
                    <a href="mailto:webmaster@example.com" className='flex gap-2'>    
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                    </svg>
                    info@paridconsulting.al </a>
                </address>
                <p className='mt-2 opacity-75'>E Hënë - 9:00 - 17:00</p>
                <p  className='opacity-75'>E Shtunë - 9:00 - 14:00</p>
              </div> 
                       


               <div className='flex flex-col justify-center items-center ' >
               
                <img src={whiteLogoja} alt=' ' className='w-16 h-16 pb-1  '  />
                <p className='tracking-tight text-lg font-medium'>Parid Consulting</p>
                <div className='mt-4'>
                 <a href='https://www.instagram.com/paridconsulting/?igshid=YzdkMWQ2MWU%3D'> <InstagramIcon  className='mx-2 hover:scale-110 duration-150 ease-in-out'/></a>
                 <a href='https://www.facebook.com/people/Parid-Consulting-Shpk/100063785520276/'><FacebookIcon className='mx-2 hover:scale-110 duration-150 ease-in-out'/></a>
                 <a href='https://www.linkedin.com/company/parid-consulting-shpk/'><LinkedInIcon className='mx-2 hover:scale-110 duration-150 ease-in-out'/></a> 
                </div>
               </div>
      
            </div>
             <div className='flex justify-center  '>
           <p className='tracking-wider max-w-full py-4  border-t-2   text-white text-base  flex flex-shrink-0 justify-center items-center'>© 2022 Copyright ParidConsulting Shpk (NUIS: L72307048U). All rights reserved</p>
        </div>
  </div>

  )
}

export default Footer