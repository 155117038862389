import React from 'react'

const Loading = () => {
  return (
    <div className='loading-page'>
        <div class="lds-ripple">
           <div>
           </div>
        </div>
    </div>
   
  )
}

export default Loading