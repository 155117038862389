import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";
import './i18next'



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
     <React.StrictMode> 
      <HashRouter>
        <App />
      </HashRouter>
     </React.StrictMode>
);

reportWebVitals();
