import React,{useState} from "react";
import { Button, TextField } from "@mui/material";

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssignmentIcon from '@mui/icons-material/Assignment';

import Backdrop from '@mui/material/Backdrop';
import { useInView } from 'react-intersection-observer'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from "react-helmet";
import { t } from "i18next";
const Inxhiniermodale = ({english,changeLanguage,hanldeLanguage,shqip,italian}) => {
    const {ref:homeRef3,inView:homeVisible3}=useInView();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
  return (
    <div  ref={homeRef3} >
       <Helmet> 
        <meta charSet='utf-8' />
        <title>ParidConsulting</title>
        <meta name='keywords' content='parid' />
        <meta name='description' content='paridconsulting' />
      </Helmet>
          <button  onClick={handleOpen} className='w-96 h-72 shadow-lg border butoni-aplikimpune aplikim_pune  rounded-2xl hover:shadow-xl' >
        
        <div >
            <AccountBalanceIcon  className='text-black icon' fontSize='large' />
            <p className='tracking-wider mt-6   text-lg '>
             <p>{t("Inxhinjer")}</p> 
            </p>
        </div>

       </button>
          <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition 
  >
    <Fade in={open}>
      <Box style={{height:'800px'}} className="bg-white p-10   my-10 mx-auto container rounded-2xl">
        <div className="grid  ">
        <button className="font-semibold text-lg flex justify-end" onClick={handleClose}><CloseIcon/></button>
        
        
       
   <form action="https://formsubmit.co/mateovelo99@gmail.com" method="POST"  className='flex flex-col  xl:w-auto lg:w-auto md:w-auto sm:w-56  gap-5'>
 
      <p className='font-bold text-2xl tracking-wide text-left'>{t("InxhinjerMeKohetePlote")}</p>
     
      <p className='text-left'>{t("PlotesoFushat")}</p>
      <span className="font-normal"> {t("ngarkoCV")}<input type="file" name="CV"  accept="application/pdf" /></span> 
       <TextField type='text' name='Name' label={`${t("futemrin")}`} size='small' required/>
       <TextField type='text' name='Numri' label={`${t("numri")}`} size='small'  />
       <TextField type='email' name='Email' label={`${t("futemail")}`} required />
       <input type='hidden' name='_captcha' value='false'/>
       <input type='hidden' name='_next' value='http://localhost:3000/Thankyou'/>
        <textarea type='text' name='Komenti' placeholder={`${t("Koment")}`}  className='p-3  bg-transparent border text-gray-500 border-gray-400 rounded-md '  required>
        </textarea>
       <span> <Button  variant='contained' type='submit'>{t("dergo")}</Button></span>
  </form>




        </div>
      </Box>
    </Fade>
  </Modal>
  </div>
  )
}

export default Inxhiniermodale