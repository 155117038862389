import React,{useEffect} from "react";
import { Button, TextField } from "@mui/material";
import Footer from "../navbar/Footer";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ScrollToTop from "react-scroll-to-top";
const PasuriteEpaluajtshme = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {t,i18n}=useTranslation()
  return (
    <div>
    <ScrollToTop smooth />
    <Helmet> 
        <meta charSet='utf-8' />
        <title>PasuriteEpaluajtshme</title>
        <meta name='description' content='ParidConsulting is your choice' />
        <meta name='keywords' content='parid, consulting, finace, smartsolution' />
    </Helmet>
      <body className="bg-slate-100 shadow-md text-lg  ">
      <div className="mx-auto max-w-full  py-6 px-8 xl:px-48  md:px-14 sm:px-6 lg:px-8">
       
          <h1 className=" tracking-tight text-5xl text-left mt-6">
             {t('pasuria')}
            </h1>
         
        </div>
        <div className="mx-auto max-w-full xl:pb-36 lg:pb-36 md:pb-36 sm:pb-0 xl:px-48 md:px-14 py-6 px-4 text-lg sm:px-6 lg:px-8">
          <div className="mt-2 mb-6  xl:flex md:grid sm:grid gap-20 ">
          <ul  className='xl:w-7/12  lg:w-full md:w-full  sm:w-full px-4 mb-9 justify-start text-left items-start grid list-decimal'>
           
                 <p className="px-1 py-4 italic">
                 {t('astudio')}
                </p>

              <div className="ml-6">
                <li className="mb-2">
 
                   <p> {t('option5')}</p>
                  <ul className="list-disc p-4">
  
                    <li>{t('apartamente')}</li>
                    <li>{t('komerciale')}</li>
                     <li>{t('hotel')}</li>
                    <li>{t('Hec')}</li>      
                     <li>{t('traull')}</li>
                  </ul>
                </li>

                <li className="mb-2">
               
                  <p>{t('vlersimtelinjave')}</p>

                  <ul className="list-disc p-4">
                  
                     <li>{t('automjete')}</li>
                  </ul>
                </li>
              </div>
            </ul>
            <div className="xl:w-5/12 lg:w-full md:w-full sm:w-full xl:h-2/3 lg:h-full md:h-full sm:h-full rounded-lg shadow-lg text-lg  border-t-4 border-l-4 border-r-4">

              <form
                action="https://formsubmit.co/info@paridconsulting.al"
                method="POST"
                className="grid  gap-5 kontakt-forma"
              >
                 <p className="font-semibold tracking-wide text-left">
                {t('keninevojepersherbimintone')}  
                  </p>
                {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}
                <p className="text-left">
                 {t('nakontaktoni')}  
                  </p>
                {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}
              
                    <TextField
                    type="text"
                    name="Name"
                    label={t('futemrin')}  
                    size="small"
                    required
                  />
                {/* //////////////////////////////////////////////////////////////////////////////////////////// */}
             
                  <TextField
                    type="text"
                    name="Numri"
                    label={t('numri')}  
                    size="small"
                    required
                  />

                <input type="hidden" name="_captcha" value="false" />
                <input
                  type="hidden"
                  name="_next"
                  value="http://localhost:3000/Thankyou"
                />
                <select
                   name={t('zgjidhninjesherbim')} 
                  className="w-full h-10 bg-transparent border text-gray-500 border-gray-400 rounded-md"
                >
                  <option>{t('option1')} </option>
                  <option>{t('option2')} </option>
                  <option>{t('option3')} </option>
                  <option>{t('option4')} </option>
                  <option>{t('option5')} </option>
                  <option>{t('option6')} </option>
                </select>
                {/* /////////////////////////////////////////////////////// */}
                <Button variant="outlined" type="submit">
                    {t('dergo')}
                  </Button>
              </form>
            </div>
          </div>
        </div>
      </body>

 
    </div>
  );
};

export default PasuriteEpaluajtshme;
