import React ,{useEffect} from 'react'
import { Button, TextField } from "@mui/material";
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import kryeministria from '../../assetes/lajme/kryeministria.jpg'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next'
import ScrollToTop from "react-scroll-to-top";


const Aktinormativ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {t,i18n}=useTranslation()
    const downloapdf= ()=>{
        
        const doc= new jsPDF()
        
          doc.autoTable({
            styles:{fillcolor:[0,0,0]},
            columnStyles:{0:{halign:'center',fontSize:  11,font: 'helvetica',fillcolor:[0,0,0]}},
            margin:{top :  20},
            body:[
                [`
                AKTI NORMATIV
                Nr. 27, datë 1.7.2021

                PËR NJË SHTESË NË LIGJIN NR.9920, DATË 19.5.2008, “PËR PROCEDURAT
                TATIMORE NË REPUBLIKËN E SHQIPËRISË”, TË NDRYSHUAR
                Në mbështetje të nenit 101 të Kushtetutës, me propozimin e ministrit të Financave dhe
                Ekonomisë, Këshilli i Ministrave
                VENDOSI:
                
                Neni 1
                Në kreun XV, pas pikës 6, të nenit “Dispozita kalimtare”, të ligjit nr. 9920, datë 19.5.2008,
                “Për procedurat tatimore”, të ndryshuar, shtohet pika 7, me këtë përmbajtje:
                “7. Përjashtimisht parashikimeve në këtë ligj, për kundërvajtjet administrative që lidhen me
                afatet e hyrjes në fuqi dhe zbatimin efektiv të ligjit nr. 87/2019, “Për faturën dhe sistemin e
                monitorimit të qarkullimit”, të ndryshuar, zbatimi i sanksioneve do të fillojë të aplikohet pas
                datës 1 janar 2022.”.
                
                Neni 2
                Ky akt normativ hyn në fuqi menjëherë dhe botohet në Fletoren Zyrtare.
                
                
                                                                                                         KRYEMINISTËR
                                                                                                             Edi Rama`]    
            ]
          })

        doc.save('aktinormativ.pdf')
    }
  return (
    <div>
          <ScrollToTop smooth />
 <Helmet> 
        <meta charSet='utf-8' />
        <title>ParidConsulting</title>
        <meta name='keywords' content='parid' />
        <meta name='description' content='paridconsulting' />
  </Helmet>
      <body className="bg-slate-100 shadow-md text-lg ">
      <div className="mx-auto max-w-full  py-6 px-8 xl:px-56  md:px-14 sm:px-6 lg:px-8">
          <h1 className=" tracking-tight text-4xl   mt-6 text-left">
         
             {t('shteseligji')}
          </h1>
        </div>
        <div className="mx-auto max-w-full xl:pb-0 lg:pb-0 md:pb-36 sm:pb-0 xl:px-48 lg:px-8 md:px-14 sm:px-8  py-6  text-lg   ">
          <div className="mt-2 mb-6  xl:flex md:grid sm:grid gap-20 ">
            <p  className='xl:w-7/12  lg:w-full md:w-full  sm:w-full px-8   mb-9 justify-start items-start text-left grid '>
              <div >
                {/* //////////////////////////////////////////////////////////////////////////////////////////////////////// */}
               
                <p className="mb-2 ">
                {t('Nekreun')}
               
                <Button onClick={downloapdf}>Lexo më shumë</Button>
               </p>
                {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
               
              </div>
              <div><img src={kryeministria} alt=''/></div>
            </p>
            <div className="xl:w-5/12 lg:w-full md:w-full  sm:w-full xl:h-2/3 lg:h-full md:h-full sm:h-full rounded-lg shadow-lg text-lg  border-t-4 border-l-4 border-r-4">
              <form
                action="https://formsubmit.co/info@paridconsulting.al"
                method="POST"
                className="grid  gap-5 kontakt-forma"
              >
             
                   <p className="font-semibold tracking-wide text-left">
                   {t('keninevojepersherbimintone')}  
                  </p>
                {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}
              
                   <p className="text-left">
                   {t('nakontaktoni')}  
                  </p>
                {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}
              
            
                  <TextField
                    type="text"
                    name="Name"
                    label={t('futemrin')}  
                    size="small"
                    required
                  />
                {/* //////////////////////////////////////////////////////////////////////////////////////////// */}
              
                  <TextField
                    type="text"
                    name="Numri"
                    label={t('numri')}  
                    size="small"
                    required
                  />
                <input type="hidden" name="_captcha" value="false" />
                <input
                  type="hidden"
                  name="_next"
                  value="http://localhost:3000/Thankyou"
                />
                <select
                  name={t('zgjidhninjesherbim')} 
                  className="w-full h-10 bg-transparent border text-gray-500 border-gray-400 rounded-md"
                >
                  
                  <option>{t('option1')} </option>
                  {/* /////////////////////////////////////////////////////// */}
   
                   <option>{t('option2')} </option>
                  {/* /////////////////////////////////////////////////////// */}
 
                  <option>{t('option3')} </option>
                  {/* /////////////////////////////////////////////////////// */}
     
                   <option>{t('option4')} </option>
                  {/* /////////////////////////////////////////////////////// */}
                
                  <option>{t('option5')} </option>
                  {/* /////////////////////////////////////////////////////// */}
   
                    <option>{t('option6')} </option>
                </select>
                {/* /////////////////////////////////////////////////////// */}
             
                    <Button variant="outlined" type="submit">
                    {t('dergo')}
                  </Button>
              </form>
            </div>
          </div>
        </div>
      </body>

    </div>
  )
}

export default Aktinormativ