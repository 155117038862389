import React, { useState,useEffect } from 'react'

import para1 from "../../assetes/stafi/para1.jpg"
import para2 from "../../assetes/stafi/para2.jpg"
import para3 from "../../assetes/stafi/para3.jpg"
import para5 from "../../assetes/stafi/para5.jpg"
import { Button, Carousel } from 'antd';
import GavelIcon from '@mui/icons-material/Gavel';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SchoolIcon from '@mui/icons-material/School';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { Link } from 'react-router-dom'
import Slideri from './slider/Slideri';
import { ArrowRightAlt } from '@mui/icons-material'
import { useInView } from 'react-intersection-observer'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next'
import ScrollToTop from "react-scroll-to-top";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

   const {t,i18n}=useTranslation()

  const {ref:homeRef1,inView:homeVisible1}=useInView();
  const {ref:homeRef2,inView:homeVisible2}=useInView();
  const {ref:homeRef3,inView:homeVisible3}=useInView();
  const {ref:homeRef4,inView:homeVisible4}=useInView();
  const {ref:homeRef5,inView:homeVisible5}=useInView();
  const {ref:homeRef6,inView:homeVisible6}=useInView();
  const {ref:homeRef7,inView:homeVisible7}=useInView();


  return (
    <div>
      <Helmet> 
        <meta charSet='utf-8' />
        <title>Parid Consulting</title>
        <meta name='description' content='' />
        <meta name='keywords' content='parid, consulting, finance, smartsolution' />
    </Helmet>
      <main>
      <ScrollToTop smooth />

        <div ref={homeRef1} className='containeri'>
          <div className='text-container'>
            
             <p  className='text1'>Parid Consulting</p>
             <p className='text2 mt-5' >{t('pershkrimi')}</p>
            
            <span className={`${'rocket'} ${homeVisible1 ? 'animate-text' :''}`} >
             
             <div className='buton-container '>
               <Link to='/ParidConsulting' >
                  <Button className='buton1-container'>{t('pyetjet')}</Button>
              </Link> 
              <Link  to='/kontakt'> 
                <Button className='buton2-container'>{t('kontakt')}</Button>
              </Link>
              </div>
            </span>
           
            
          </div>
          <div className='carousel-container'>
                      

          <Carousel className='carousel' autoplay fade>
              <div>
                <img className='rounded-lg'src={para3} />
              </div>
              <div>
                <img className='rounded-lg'src={para5} />
              </div>
              <div>
                <img className='rounded-lg'src={para1} />
              </div>
              <div>
                <img className='rounded-lg'src={para2} />
              </div>
            </Carousel>
          </div>
        </div>
  

        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        
        <div className='container bg-slate-200 rounded-3xl max-w-full sherbime_bg'>

          <div ref={homeRef3}  className='border-black container mx-auto '>
         <div className='flex justify-center '>
          <div><p className='txt_sherbimet '>{t('sherbime')}</p></div> 
          </div>  
           
            <div className='sherbimet'>
             <Link className='karta_sherbimeve  bg-white border-b-4 border-gray-300 border-opacity-80'  to='/KosulenceFinanciare'>
                <span>
                    <GavelIcon className='text-black icon' fontSize='large' />
                    <p className='tracking-wider mt-4 text-lg '>
                    {t('konsulencefinanciare')}
                    </p>
                </span>
               </Link>
                <Link className='karta_sherbimeve bg-white border-gray-300 border-opacity-80'  to='/SherbimKontabiliteti'>
                  <span>
                    <SsidChartIcon className='text-black icon' fontSize='large' />
                     <p className='tracking-wider mt-4 text-lg '>
             
                    {t('kontabiliteti')}

                    </p>
                  </span>
                </Link>
                <Link className='karta_sherbimeve bg-white border-gray-300 border-opacity-80'  to='/KonsulenceBanke'>
              <span>
                  <AccountBalanceIcon className='text-black icon' fontSize='large' />
                  <p className='tracking-wider mt-4 text-lg '>
        
                      {t('konsulence')}

                     </p>
                </span>
                </Link>

                <Link className='karta_sherbimeve bg-white border-gray-300 border-opacity-80'  to='/SherbimFiskal'>
                  <span>
                  <AssignmentIcon className='text-black icon' fontSize='large' />
                  <p className='tracking-wider mt-4 text-lg '>
                
                     {t('fiskalue')}

                    </p>
                </span>
                </Link>
               <Link className='karta_sherbimeve bg-white border-gray-300 border-opacity-80' to='/PasuriteEpaluajtshme'>
                   <span>
                     <HomeIcon className='text-black icon' fontSize='large' />
   
                    <p className='tracking-wider mt-4 text-lg '>
                    {t('pasuria')}

                    </p>
                   </span>
               </Link>
              <Link  className='karta_sherbimeve bg-white border-gray-300 border-opacity-80' to='/KosulenceFinanciare'>
                 <span >
                   <PeopleAltIcon className='icon' fontSize='large' />
         
                    <p className='tracking-wider mt-4 text-lg '>
                    {t('burime')}

                    </p>
                 </span>
              </Link>
            </div>


          </div>
        </div>

        {/* /////////////////////////////////////////////////////////////////////////////// */}
        <div className='container3'>
          
          <div className='inside-container3'>

              <div><p className='text1'>{t('certifikime')}</p></div> 
              <div className='cards-container3'>
              
                <div className='item-container3'> 
               
                    <div className='hover:-translate-y-2  hover:text-gray-100 ease-in-out duration-300 '>
                      <SchoolIcon className='icon' fontSize='large' />
                    </div>
                    <p className='tracking-wider mt-6  text-lg   '>
                     {t('Trajnime')}
                    </p>
                    <Link to='/Certfikimedhetrajnime' className='flex justify-center items-end'>
                      <button className='button shiko_btn hover:text-gray-100 ease-in-out duration-300'>
                      {t('Shiko')}
                      </button>
                   </Link>
                
                </div>
              
                <div className='item-container3'> 
                  <div > 
                    <div className='hover:-translate-y-2  hover:text-gray-100 ease-in-out duration-300 '>
                     <LightbulbIcon className='icon' fontSize='large' />
                    </div>
                     <p className='tracking-wider mt-6 text-lg   '>
                       {t('trajnimetepergjithshme')}
                     </p>
                      <Link to='/Trajnimetepergjithshme' className='flex justify-center items-end'>
                       <button className='button shiko_btn hover:text-gray-100 ease-in-out duration-300'>
                        {t('Shiko')}
                       </button>
                     </Link>
                   </div>
                </div>
               
                <div className='item-container3'> 
                
                  <div className='hover:-translate-y-2  hover:text-gray-100 ease-in-out duration-300 '>
                  <BusinessCenterIcon className='icon' fontSize='large' />
                  </div>

                  <p className='tracking-wider mt-6 text-lg  '>
                   {t('Aplikim')}
                  </p>
                 
                  <div>

                    <Link to='/Aplikimpune'  className='flex justify-center items-end'>
                    <button className='button shiko_btn'>
                    {t('Shiko')} 
                    </button>
                    </Link>
                  </div>           
                </div>
              </div>
          </div>


          <div id='lajme' className='border-black text-white  inside-container3 relative'>
            <div ref={homeRef7} className='flex justify-center'>
        
              <div><p className='text1 '>{t('lajme')}</p></div> 
      
            </div>
            <div ref={homeRef7}   className='p-2 flex flex-col items-center w-full  gap-6 h-72 rounded-lg scroll-smooth scrollbar  overflow-x-none overflow-y-auto'>
          

                     <span className='w-full'>
                        <Link to='/Aktinormativ' className='text-gray-600  tracking-wider'> 
                           <div className=' flex justify-between lajme_hover items-center bg-slate-50 rounded-lg  h-10 p-10 text-left hover:cursor-pointer hover:translate-x-2 ease-in-out duration-300  '>

                                    <p>{t('shteseligji')} </p>

                               <ArrowRightAlt className='text-gray-700 icon'/>
                           </div>
                        </Link>
                     </span>

                    <span className='w-full'>
                      <Link to='/Deklarimiifitimit' className='text-gray-600 tracking-wider'>
                        <div className=' flex justify-between lajme_hover items-center bg-slate-50 rounded-lg  h-10 p-10 text-left  hover:cursor-pointer hover:translate-x-2 ease-in-out duration-300 '>
                            
                              <p>{t('deklarimi')}</p> 

                            <ArrowRightAlt className='text-gray-700 icon'/>
                        </div>
                      </Link>
                    </span>

                    <span className='w-full'>
                      <Link to='/Teardhuratpersonale' className='text-gray-600 tracking-wider'>
                          <div className=' flex justify-between lajme_hover items-center bg-slate-50 rounded-lg  partneret_tane_txt h-10 p-10 text-left  hover:cursor-pointer hover:translate-x-2 ease-in-out duration-300 '>
                           
                                <p>{t('deklarimtatimi')} </p>
                              <ArrowRightAlt className='text-gray-700 icon'/>
                          </div>
                      </Link>
                    </span>

                    <span className='w-full'>
                      <Link to='/Teardhuratpersonale' className='text-gray-600 tracking-wider'>
                        <div className=' flex justify-between lajme_hover items-center bg-slate-50 rounded-lg  h-10 p-10 text-left  hover:cursor-pointer hover:translate-x-2 ease-in-out duration-300 '>

                              <p>{t('benefite')}</p>

                            <ArrowRightAlt className='text-gray-700 icon'/>
                        </div>
                      </Link>
                   </span>
            </div>
          </div>
         
        </div>

        <div >
        <span>
          <Slideri/>
         </span>
          </div>
    


      </main>

     
    </div>
  )
}

export default Home