import React,{useEffect} from "react";
import Navbar from "../navbar/NavItem";
import Footer from "../navbar/Footer";
import fotorreth from "../../assetes/rrethnesh/fotorreth.jpg";
import { Helmet } from "react-helmet";
import marigleni from '../../assetes/stafi/marigleni.jpeg'
import fotoahmeti from '../../assetes/stafi/fotoahmeti.jpeg'
import fotoilva from '../../assetes/stafi/fotoilva.jpeg'
import staricon from '../../assetes/stafi/staricon.png'

import jus1 from '../../assetes/stafi/jus1.jpeg'
import anxhi from '../../assetes/stafi/anxhi.jpeg'
import rigersa from '../../assetes/stafi/rigersa.jpeg'
import Kledi from '../../assetes/stafi/Kledi.jpeg'
import para45  from  '../../assetes/stafi/para45.jpeg'
import { useTranslation } from "react-i18next";
import ScrollToTop from "react-scroll-to-top";
import Financieraplikim from "../aplikimepune/Financieraplikim";
const ParidConsulting = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {t}=useTranslation()

  return (
    <>

      <div>
      <ScrollToTop smooth />
      <Helmet> 
        <meta charSet='utf-8' />
        <title>Consulting</title>
        <meta name='description' content='ParidConsulting is your choice' />
        <meta name='keywords' content='parid, consulting, finace, smartsolution' />
      </Helmet>
        <body className=" bg-transparent  text-white">
        <div className=" max-w-full  py-6 px-8 xl:px-72  md:px-14 sm:px-4 lg:px-8">
            

            <p className="textvecant">{t('PerseParid')}</p>

          
          </div>
          <div className=" max-w-full   xl:px-72 md:px-14 py-6 px-8 text-lg sm:px-8 lg:px-8">
            <div className="mt-2 mb-6 xl:flex lg:grid md:grid sm:grid gap-10 justify-between ">
            
               <p className="xl:w-5/12 lg:w-full md:w-full  sm:w-full  mb-9 text-lg text-left">
                 {t('RrethneshPershkrimi')}
                </p>

           

              <img
                src={para45}
                alt=""
                className="xl:w-5/12 lg:w-full  md:w-full sm:w-full rounded-lg shadow-lg"
              />
            </div>
          

             <p className="bg-white p-8 italic text-lg border-l-8 text-slate-700 border-l-blue-500 shadow-lg rounded-lg text-left">
                {t('we')}
              </p>

                <p className="mt-6 mb-6 text-lg text-left">
               {t('studio')}
              </p>

         
               <p className="text-left text-lg">
               {t('offer')}
              </p>
          </div>
        </body>

        <body style={{fontFamily: 'IBM Plex Sans Condensed'}} className="bg-slate-50 text-white  mt-6 pb-6">
          <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
  
              <h1 className=" tracking-tight text-5xl pt-2 text-black  ">
                {t('stafi')}
              </h1>
          </div>

          <div className="mx-auto flex flex-col items-center ">
          <div className="xl:flex   md:grid grid-cols-2   sm:grid    ">
       
             
            <div className="grid kart justify-center relative  items-center photo-puntoret  gap-2 border text-black m-6  shadow-xl transition-all   bg-white">
              <img src={Kledi} alt='' />
              <div className="intro  ">
                   <h1 className="text-2xl tracking-tight flex justify-start pl-3">Kledi Hasani</h1>
                   <p className="paragrafi grid">  {t('drejtor')}</p>
                  <div className="flex pl-3"> 
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  </div>  
                
              </div>
              </div>
            
              <div className="grid kart justify-center relative  items-center photo-puntoret gap-2 border text-black m-6 shadow-md     bg-white">
              <img src={jus1} alt='' />
              <div className="intro  ">
                   <h1 className="text-2xl tracking-tight flex justify-start pl-3">Jusilda Nuro</h1>
                   <p className="paragrafi grid">   {t('supervizore')} </p>
                  <div className="flex pl-3"> 
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  </div>  
                
              </div>
              </div>
                
              <div className="grid kart relative justify-center items-center gap-2 border  photo-puntoret  m-6   shadow-md bg-white">
              <img src={marigleni} alt=''/>
              <div className="intro  ">
                   <h1 className="text-2xl tracking-tight flex justify-start pl-3">Mariglen Hoxha</h1>
                   <p className="paragrafi grid">    {t('supervizor')} </p>
                  <div className="flex pl-3"> 
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  </div>  
                
              </div>
              </div>
              <div className="grid kart relative justify-center items-center gap-2 border photo-puntoret  m-6  shadow-md  bg-white">
              <img src={fotoilva} alt=''/>
              <div className="intro  ">
                   <h1 className="text-2xl tracking-tight flex justify-start pl-3">Ilva Mihali</h1>
                   <p className="paragrafi grid">  {t('financiere')}  </p>
                  <div className="flex pl-3"> 
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  </div>  
                
              </div>
              </div>
             
              
            </div>

            <div className="xl:flex   md:grid grid-cols-2   sm:grid    ">
            <div className="grid kart relative justify-center items-center gap-2 border  photo-puntoret  m-6  shadow-md  bg-white">
              <img src={anxhi} alt=''/>
              <div className="intro  ">
                   <h1 className="text-2xl tracking-tight flex justify-start pl-3">Angela Rusmali</h1>
                   <p className="paragrafi grid"> {t('financiere')}  </p>
                  <div className="flex pl-3"> 
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  </div>  
                
              </div>
              </div>

              <div className="grid kart relative justify-center items-center gap-2 border photo-puntoret  m-6  shadow-md  bg-white">
              <img src={fotoahmeti} alt=''/>
              <div className="intro  ">
                   <h1 className="text-2xl tracking-tight flex justify-start pl-3">Ahmet Hyka</h1>
                   <p className="paragrafi grid"> {t('financier')}  </p>
                  <div className="flex pl-3"> 
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  </div>  
                
              </div>
              </div>
              <div className="grid kart relative justify-center items-center gap-2 border  photo-puntoret  m-6  shadow-md  bg-white">
              <img src={rigersa} alt=''/>
              <div className="intro  ">
                   <h1 className="text-2xl tracking-tight flex justify-start pl-3">Rigersa Aliu</h1>
                   <p className="paragrafi grid"> {t('financiere')}  </p>
                  <div className="flex pl-3"> 
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  <img className="ylli" src={staricon} alt=''/>
                  </div>  
                
              </div>
              </div>

              <div className="grid justify-center  items-center gap-2 border p-7 w-64 h-80 m-6 rounded-md shadow-md bg-white">
                  <p className="text-black pr-5 pl-5 text-center text-3xl"><span>
                    {t('ketumund')}
                    </span></p>
                  <Financieraplikim/>
              </div>
            </div>
          </div>

   

        </body>
      </div>
   
    </>
  );
};

export default ParidConsulting;
