import React,{useEffect} from 'react'
import Footer from "../navbar/Footer";
import { Button, TextField } from "@mui/material";
import { Helmet } from "react-helmet";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import PlaceIcon from '@mui/icons-material/Place';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import Harta from '../harta/Harta';
import { useTranslation } from "react-i18next";
import ScrollToTop from "react-scroll-to-top";

const Kontakt = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {t,i18n}=useTranslation()
  return (
    <div>
    <ScrollToTop smooth />
    <Helmet> 
     <meta charSet='utf-8' />
     <title>Kontakt</title>
     <meta name='description' content='Konsulence financiare' />
     <meta name='keywords' content='parid, consulting, finace, smartsolution' />
 </Helmet>
   <body className="bg-slate-100 shadow-md  w-full ">

   <div className=" max-w-full flex items-center py-6 px-8 xl:px-72  md:px-14 sm:px-4 lg:px-8">
       <h1 className='textvecant'>
  
            {t('Kontakto')}
       </h1>
     </div>

  
     <div className=" max-w-full xl:pb-10 lg:pb-10 md:pb-36 sm:pb-10 xl:px-72 md:px-14 py-6 px-8 text-lg sm:px-6 lg:px-8">
       <div className="mt-2 mb-6  xl:flex lg:grid md:grid sm:grid gap-20 ">
         <div className="xl:w-7/12  lg:w-full md:w-full  sm:w-full text-left mb-9  items-center xl:grid lg:flex md:flex sm:grid justify-between ">
       
   
            <Harta /> 

       
           <div  className='xl:w-7/12  lg:w-full md:w-full  sm:w-full text-left mb-9  items-center justify-center'>
           
             <address>
                    <a href="info@paridconsulting.al" className='flex gap-2'>    
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                    </svg>
                    info@paridconsulting.al </a>
                </address>
                
              <div className='flex mt-5 gap-1'>

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
               <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
               <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
              </svg>

                <a className='flex'
                       href="https://www.google.com/maps/place/Rruga+Abdyl+Frash%C3%ABri,+Tirana,+Albania/@41.3191333,19.8155158,17z/data=!3m1!4b1!4m5!3m4!1s0x13503102cb31d51b:0x553d27289980062e!8m2!3d41.3191293!4d19.8177045">
                <span className='text-gray-700 text-left'>Rruga Abdyl Frashëri, Tirana, Albania</span>
                  </a>
                  </div>

                <div className='flex items-center mt-4'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                  </svg>

                  <a href="tel:123-456-7890" className='text-gray-700'> 
                    +355 69 60 60 818
                  </a>
                </div>

                <div className='mt-6'>
                 <a href='https://www.instagram.com/paridconsulting/?igshid=YzdkMWQ2MWU%3D'> <InstagramIcon fontSize='large' className='text-blue-600 mx-2 hover:scale-110 duration-150 ease-in-out'/></a>
                 <a href='https://www.facebook.com/people/Parid-Consulting-Shpk/100063785520276/'><FacebookIcon fontSize='large' className='text-blue-600 mx-2 hover:scale-110 duration-150 ease-in-out'/></a>
                 <a href='https://www.linkedin.com/company/parid-consulting-shpk/'><LinkedInIcon fontSize='large' className='text-blue-600 mx-2 hover:scale-110 duration-150 ease-in-out'/></a> 
                </div>

           </div>
         </div>
         <div className="xl:w-5/12 lg:w-full md:w-full sm:w-full xl:h-2/3 lg:h-full md:h-full sm:h-full rounded-lg shadow-lg text-lg  border-t-4 border-l-4 border-r-4">
           <form action="https://formsubmit.co/info@paridconsulting.al" method="POST" className="grid  gap-5 kontakt-forma">     
             {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}

             <div className='grid kontakt_div1 gap-4 '>

             
            
                  <TextField
                    type="text"
                    name="Name"
                    label={t('futemrin')}  
                    size="small"
                    required
                  />
             {/* //////////////////////////////////////////////////////////////////////////////////////////// */}

              <TextField
                    type="text"
                    name="Name"
                    label={t('futemail')}  
                    size="small"
                    required
                  />
            </div>


             <input type="hidden" name="_captcha" value="false" />
             <input
               type="hidden"
               name="_next"
               value="http://localhost:3000/Thankyou"
             />
            <div className='grid gap-4 kontakt_div2'>

            <TextField
                    type="text"
                    name="Numri"
                    label={t('numri')}  
                    size="small"
                    required
                  />
              
             <select 
               required
               name={t('zgjidhninjesherbim')} 
               className="w-full h-10 bg-transparent border text-gray-500 border-gray-400 rounded-md"
             >
               <option>{t('option1')} </option>
               <option>{t('option2')} </option>
               <option>{t('option3')} </option>
               <option>{t('option4')} </option>
               <option>{t('option5')} </option>
               <option>{t('option6')} </option>

             </select>

             </div>
       

             <TextField label={t('Subjekt')} type='text' fullWidth  size='small' />

        
                <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                
                placeholder={t('Mesazhiii')}
                fullWidth
                className='rounded-lg p-2 border'
                />
             {/* /////////////////////////////////////////////////////// */}
            
                <Button variant="outlined" type="submit">
                    {t('dergo')}
                  </Button>
           </form>
         </div>
       </div>

     </div>
     
   </body>


 </div>
  )
}

export default Kontakt

