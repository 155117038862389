import { Button, TextField } from "@mui/material";
import React,{useEffect} from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ScrollToTop from "react-scroll-to-top";
const KosulenceFinanciare = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {t,i18n}=useTranslation()
  return (
    <div>
            <ScrollToTop smooth />
       <Helmet> 
        <meta charSet='utf-8' />
        <title>konsulence financiare</title>
        <meta name='description' content='Konsulence financiare' />
        <meta name='keywords' content='parid, consulting, finace, smartsolution' />
    </Helmet>
      <body className="bg-slate-100 shadow-md  ">
      <div className="mx-auto max-w-full  py-6 px-8 xl:px-48  md:px-14 sm:px-6 lg:px-8">
          <p className="text1">

            {t('option1')}
          </p>
         
        </div>
        <div className="mx-auto max-w-full xl:pb-0 lg:pb-0 md:pb-36 sm:pb-0 xl:px-48 md:px-14 py-6 px-4 text-lg sm:px-6 lg:px-8">
          <div className="mt-2 mb-6  xl:flex md:grid sm:grid gap-20 ">
          <ul  className='xl:w-7/12  lg:w-full md:w-full  sm:w-full  mb-9 justify-start text-left items-start grid list-decimal'>
              <div className="ml-6 ">
                {/* //////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              
                 <li className="mb-2 ">
                   {t('pika1')}
                  </li>
                {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              
                   <li className="mb-2 ">
                   {t('pika2')}
                  </li>
                {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              
                 <li className="mb-2 ">
                   {t('pika3')}
                  </li>
                {/*///////////////////////////////////////////////////////////////////////////////////////////////////////////  */}
                
                  <li className="mb-2 ">
                   {t('pika4')}
                  </li>
                {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              
                   <li className="mb-2 ">
                   {t('pika5')}
                  </li>
                {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              
                  <li className="mb-2 ">
                   {t('pika6')}
                  </li>
                {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              
                  <li className="mb-2 ">
                   {t('pika7')}
                  </li>
                {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
               
                  <li className="mb-2 ">
                   {t('pika8')}
                  </li>
                {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
               
                  <li className="mb-2 ">
                   {t('pika9')}
                  </li>
                {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
              
                <li className="mb-2 ">
                   {t('pika10')}
                  </li>
              </div>
            </ul>
            <div className="xl:w-5/12 lg:w-full md:w-full sm:w-full xl:h-2/3 lg:h-full md:h-full sm:h-full rounded-lg shadow-lg text-lg  border-t-4 border-l-4 border-r-4">
              <form
                action="https://formsubmit.co/info@paridconsulting.al"
                method="POST"
                className="grid  gap-5 kontakt-forma"
              >
               
                <p className="font-semibold  tracking-wide text-left">
                {t('keninevojepersherbimintone')}  
                  </p>
                {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}
              
                 <p className="text-left">
                 {t('nakontaktoni')}  
                  </p>
                {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}
              
                    <TextField
                    type="text"
                    name="Name"
                    label={t('futemrin')}  
                    size="small"
                    required
                  />
                {/* //////////////////////////////////////////////////////////////////////////////////////////// */}
              
                  <TextField
                    type="text"
                    name="Numri"
                    label={t('numri')}  
                    size="small"
                    required
                  />

                <input type="hidden" name="_captcha" value="false" />
                <input
                  type="hidden"
                  name="_next"
                  value="http://localhost:3000/Thankyou"
                />
                <select
                  name={t('zgjidhninjesherbim')} 
                  className="w-full h-10 bg-transparent border text-gray-500 border-gray-400 rounded-md"
                >
                  <option>{t('option1')} </option>
                  <option>{t('option2')} </option>
                  <option>{t('option3')} </option>
                  <option>{t('option4')} </option>
                  <option>{t('option5')} </option>
                  <option>{t('option6')} </option>
                </select>
                {/* /////////////////////////////////////////////////////// */}
                <Button variant="outlined" type="submit">
                    {t('dergo')}
                  </Button>
              </form>
            </div>
          </div>
        </div>
      </body>


    </div>
  );
};

export default KosulenceFinanciare;
