import React,{useEffect} from "react";
import { Button, TextField } from "@mui/material";
import Footer from "../navbar/Footer";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ScrollToTop from "react-scroll-to-top";
const SherbimFiskal = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {t,i18n}=useTranslation()
  return (
    <div>
     <ScrollToTop smooth />
     <Helmet> 
        <meta charSet='utf-8' />
        <title>SherbimFiskal</title>
        <meta name='description' content='ParidConsulting is your choice' />
        <meta name='keywords' content='parid, consulting, finace, smartsolution' />
    </Helmet>
      <body className="bg-slate-100 shadow-md text-lg ">
      <div className="mx-auto max-w-full  py-6 px-8 xl:px-48  md:px-14 sm:px-6 lg:px-8">
          
            <h1 className=" tracking-tight text-5xl text-left mt-6">
            {t('option4')}
            </h1>
        </div>
        <div className="mx-auto max-w-full xl:pb-8 lg:pb-48 md:pb-36 sm:pb-0 xl:px-48 md:px-14 py-6 px-4 text-lg sm:px-6 lg:px-8">
          <div className="mt-2 mb-6  xl:flex md:grid sm:grid gap-20 ">
          <ul  className='xl:w-7/12  lg:w-full md:w-full  sm:w-full  mb-9 justify-start items-start text-left grid list-decimal'>
              <div className="ml-6 text-left">
         
                 <li className="mb-2">
                    {t('lista1')}
                  </li>
                {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
         
                     <li className="mb-2">
                    {t('lista2')}
                  </li>
                {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
               
                <li className="mb-2">
                    {t('lista3')}
                  </li>
                {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
               
                   <li className="mb-2">
                    {t('lista4')}
                  </li>
                {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
               
                  <li className="mb-2">
                    {t('lista5')}
                  </li>
              </div>
            </ul>
            <div className="xl:w-5/12 lg:w-full md:w-full sm:w-full xl:h-2/3 lg:h-full md:h-full sm:h-full rounded-lg shadow-lg text-lg  border-t-4 border-l-4 border-r-4">
              <form
                action="https://formsubmit.co/info@paridconsulting.al"
                method="POST"
                className="grid  gap-5 kontakt-forma"
              >
        
                <p className="font-semibold tracking-wide text-left">
                  {t('keninevojepersherbimintone')}  
                  </p>
                {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}
            
                 <p className="text-left">
                 {t('nakontaktoni')}  
                  </p>
             
                  <TextField
                    type="text"
                    name="Name"
                    label={t('futemrin')}  
                    size="small"
                    required
                  />
                {/* //////////////////////////////////////////////////////////////////////////////////////////// */}
         
                 <TextField
                    type="text"
                    name="Numri"
                    label={t('numri')}  
                    size="small"
                    required
                  />

                <input type="hidden" name="_captcha" value="false" />
                <input
                  type="hidden"
                  name="_next"
                  value="http://localhost:3000/Thankyou"
                />
                <select
                  name={t('zgjidhninjesherbim')} 
                  className="w-full h-10 bg-transparent border text-gray-500 border-gray-400 rounded-md"
                >
                 <option>{t('option1')} </option>
                  <option>{t('option2')} </option>
                  <option>{t('option3')} </option>
                  <option>{t('option4')} </option>
                  <option>{t('option5')} </option>
                  <option>{t('option6')} </option>
                </select>
                {/* /////////////////////////////////////////////////////// */}
                <Button variant="outlined" type="submit">
                    {t('dergo')}
                  </Button>
              </form>
            </div>
          </div>
        </div>
      </body>


    </div>
  );
};

export default SherbimFiskal;
